import { jsPDF } from "jspdf";
import {
  pdfState,
  transformUnits,
  width2Page,
  margins,
  justPx2Page,
  debugColor,
  whiteColor,
  ReportData,
} from "./pdf_state";

import * as common_styles from "./pdf_styles";
import autoTable from "jspdf-autotable";

const _bgCommon = {
  styles: {
    font: "Roboto-Regular",
    fillColor: debugColor,
    textColor: 50,
  },
  bodyStyles: {
    font: "Roboto-Regular",
    fillColor: debugColor,
    textColor: 50,
    cellPadding: 0.5,
    lineColor: whiteColor,
    lineWidth: 0.0,
    fontSize: 7,
  },
};

export async function threeSections(
  pdf: jsPDF,
  pdfKey: string,
  [text1 = "", text2 = "", text3 = ""]
) {
  const orientation = (pdfState[pdfKey] as ReportData).orientation;
  // set some parameters
  const [x, y, w1x3] = [
    pdfState.s(orientation).xStartLine,
    pdfState.getMaxY(pdfKey) + 1.5,
    pdfState.s(orientation).width3fr.width,
  ];

  const c100 = [{ content: text1 }];
  const c101 = [{ content: text2 }];
  const c102 = [
    {
      content: text3,
    },
  ];
  // [][][]
  const sx0 = pdfState.s(orientation).width3fr.x0; //transformUnits(margins.left) + prcX2Int('0%', w1x1);
  const sx33 = pdfState.s(orientation).width3fr.x1; //transformUnits(margins.left) + prcX2Int('33%', w1x1);
  const sx66 = pdfState.s(orientation).width3fr.x2; //transformUnits(margins.left) + prcX2Int('66%', w1x1);

  await autoTable(pdf, {
    theme: "grid",
    startY: y,
    margin: { left: sx0 + 0.5 },
    tableWidth: w1x3 - 1,
    body: [c100],
    ..._bgCommon,
    columnStyles: { 0: { halign: "left" } },
  });
  await autoTable(pdf, {
    theme: "plain",
    startY: y,
    margin: { left: sx33 + 0.5 },
    tableWidth: w1x3 - 1,
    body: [c101],
    styles: _bgCommon.styles,
    bodyStyles: {
      ..._bgCommon.bodyStyles,
      //  ...common_styles.TITLE_JKPDF  // <<<<<<< !fixme:
    },
    columnStyles: { 0: { halign: "center" } },
  });
  await autoTable(pdf, {
    theme: "grid",
    startY: y,
    margin: { left: sx66 + 0.5 },
    tableWidth: w1x3 - 1,
    body: [c102],
    ..._bgCommon,
    columnStyles: { 0: { halign: "right" } },
  });
  pdfState.setY({ pdfKey: pdfKey, section: "title", y: y + 4.5 });
  return pdf;
}
export async function titleSection(pdf: jsPDF, pdfKey: string, [text1 = ""]) {
  const orientation = (pdfState[pdfKey] as ReportData).orientation;
  // set some parameters
  const [x, y, w1x1] = [
    pdfState.s(orientation).xStartLine,
    pdfState.getMaxY(pdfKey) + 1.5,
    pdfState.s(orientation).width1fr.width,
  ];

  const c100 = [{ content: text1 }];

  // []
  const sx0 = pdfState.s(orientation).width1fr.x0;

  await autoTable(pdf, {
    theme: "grid",
    startY: y,
    margin: { left: sx0 + 0.5 },
    tableWidth: w1x1 - 1,
    body: [c100],
    styles: _bgCommon.styles,
    bodyStyles: {
      ..._bgCommon.bodyStyles,
      // ...common_styles.TITLE_JKPDF  // <
    },
    columnStyles: { 0: { halign: "center" } },
  });
  pdfState.setY({ pdfKey: pdfKey, section: "title", y: y + 4.5 });
  return pdf;
}
