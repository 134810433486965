import { createAssign } from "robodux";
import { leading } from "saga-query";

import { api } from "@app/state/apis/api";
import type { ApiCtx } from "saga-query";

const REPO_NAME = "anafprovider";
export const anafRepo = createAssign<any>({
  name: REPO_NAME,
  initialState: {},
});

// not used - replaced by client fetch //
export const anafQuery = api.get<{ cui: string }>(
  "/anaf/:cui",
  { saga: leading },
  function* (ctx: ApiCtx<any, { cui: string }, any>, next) {
    ctx.cache = true;
    ctx.key = `anaf-${ctx.payload.cui}`;
    ctx.request = ctx.req({
      url: `/anaf/${ctx.payload.cui}`,
    });
    yield next();
  }
);

//(!)// important
export const launchAnafCrosscheck = api.get(
  "/aqprocess",
  function* (ctx: ApiCtx<any, any, any>, next) {
    ctx.request = ctx.req({
      url: `/aqprocess`,
    });
    yield next();
  }
);
