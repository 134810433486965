import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./state";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingBar from "react-redux-loading-bar";

import { Switch, Case } from "@app/contexts";
import { setAuthResult, isAuth0AndAma } from "./state/units/login";
import NotAuth from "./pages/startup/NotAuth";
import YesAuth from "./pages/startup/YesAuth";
import { GatherPdfData } from "./contexts";
import "./App.scss";
import { IAuth0User } from "./types";

function App() {
  const dispatch = useAppDispatch();
  const { user, isAuthenticated, error } = useAuth0();
  const isAuthAndDatabase = useAppSelector(isAuth0AndAma);
  // console.log("process.env.NODE_ENV", process.env.NODE_ENV);
  // console.log("isAuthenticated", isAuthenticated);
  // console.log("isAuth0AndAma", isAuthAndDatabase);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setAuthResult(user as IAuth0User));
    }
  }, [isAuthenticated]);
  return (
    <div className="App">
      <LoadingBar
        className={"loading1"}
        updateTime={100}
        maxProgress={95}
        progressIncrease={10}
      />
      <Switch value={isAuthAndDatabase}>
        <Case when={true}>
          <GatherPdfData>
            <YesAuth />
          </GatherPdfData>
        </Case>
        <Case when={false}>
          <NotAuth />
        </Case>
      </Switch>
      <Switch value={error}>
        <Case when={true}>
          <div className="error">
            <div>{error?.message}</div>
          </div>
        </Case>
      </Switch>
      {/* <p>&copy; 2003 - {new Date().getFullYear()} Softwiz Creation srl. All rights reserved.</p> */}
    </div>
  );
}

export default App;
