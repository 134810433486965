import proxy from "proxy-states";
import { nanoid } from "nanoid";
import _ from "lodash";
import format from "date-fns/format";
import ro from "date-fns/locale/ro";

const dateLocales = { ro: ro };

/* -------------------------------------------------------------------------- */
export interface LineData {
  lc: string;
  line: number[];
  lw: number;
}
export type ReportData = {
  pdfKey: string;
  thisLanguage: string;
  thisCurrency: string;
  logo: { apiKey: string; width: string; height: string };
  orientation: TOrientation;
  warnSignal?: undefined | string;
  Y: { [key: string]: number };
  line: LineData;
};
export interface IPdfState {
  [key: string]: ReportData | unknown;
  init: (args: Partial<ReportData>) => string;
  clear: () => void;
  s(orientation: any): TSizes;
  getAsset: (key: string, apiKey: string) => string;
  getMaxY(pdfKey: string): number;
  mainParams: (pdfKey: string) => ReportData;
  setY(arg0: { pdfKey: string; section: string; y: any }): unknown;
  persistent: Persistent;
  test: string;
  warnSignal: (pdfKey: string, message: string) => never;
  clearWarnSignal: (pdfKey: string) => void;
  getWarnSignal: (pdfKey: string) => string | undefined;
}
interface Persistent {
  lineGen: number;
  ref1001: string;
}
type TSizes = {
  fullWidth: number;
  fullHeight: number;
  marginTopFirst: number;
  marginTop: number;
  marginRight: number;
  marginLeft: number;
  xStartLine: number;
  xEndLine: number;
  width: number;
  width1fr: {
    x0: number;
    width: number;
  };
  width2fr: {
    x0: number;
    x1: number;
    width: number;
  };
  width3fr: {
    x0: number;
    x1: number;
    x2: number;
    width: number;
  };
};

export type TOrientation = "p" | "l";

/* -------------------------------- CONSTANTS ------------------------------- */

export const whiteColor = "#FFFFFF"; // '#DA5359';
export const debugColor = "#FFFFFF"; //'#DA5359'; //
export const silverColor = "#999999";
export const normalColor = "#333";
export const margins = {
  top: 40,
  bottom: 60,
  left: 40,
  width: 522,
  _top: 40 * 0.2645833333,
  _bottom: 60 * 0.2645833333,
  _left: 40 * 0.2645833333,
  _width: 522 * 0.2645833333,
};
export const pdfMeasures_portrait = {
  // https://css.paperplaza.net/conferences/support/page.php//
  page_width: 21 * 10,
  page_height: 29.7 * 10,
  margin_top_1st: 2.54 * 10,
  margin_top: 1.19 * 10,
  margin_left: 1.9 * 10,
  margin_right: 1.32 * 10,
  margin_bottom: 3.67 * 10,
  gap: 0.3 * 10,
  col_gap: 0.5 * 10,
  normalFont: 9 * 0.2645833333,
};
export const pdfMeasures_landscape = {
  // https://css.paperplaza.net/conferences/support/page.php//
  page_width: 29.7 * 10,
  page_height: 21 * 10,
  margin_top_1st: 2.54 * 10,
  margin_top: 1.19 * 10,
  margin_left: 1.9 * 10,
  margin_right: 1.32 * 10,
  margin_bottom: 3.67 * 10,
  gap: 0.3 * 10,
  col_gap: 0.5 * 10,
  normalFont: 9 * 0.2645833333,
};

export const parSafe = (x: any) => {
  try {
    return parseInt(x.replace(/_/g, ""), 10);
  } catch {
    return 0;
  }
};
const pdfMeasures = (o: TOrientation = "p") => {
  return o === "p" ? pdfMeasures_portrait : pdfMeasures_landscape;
};
/* ------------------------------- local state ------------------------------ */

export const pdfState: IPdfState = proxy({
  persistent: {
    lineGen: 1,
    ref1001: "some string",
  },
  s: (state: IPdfState, orientation: TOrientation): TSizes => {
    return {
      fullWidth: pdfMeasures(orientation).page_width,
      fullHeight: pdfMeasures(orientation).page_height,
      marginTopFirst: pdfMeasures(orientation).margin_top_1st,
      marginTop: pdfMeasures(orientation).margin_top,
      marginRight: pdfMeasures(orientation).margin_right,
      marginLeft: pdfMeasures(orientation).margin_left,
      xStartLine: pdfMeasures(orientation).margin_left,
      xEndLine:
        pdfMeasures(orientation).page_width -
        pdfMeasures(orientation).margin_right,
      width:
        pdfMeasures(orientation).page_width -
        (pdfMeasures(orientation).margin_left +
          pdfMeasures(orientation).margin_right),
      //ex
      width1fr: {
        x0: pdfMeasures(orientation).margin_left,
        width:
          pdfMeasures(orientation).page_width -
          (pdfMeasures(orientation).margin_left +
            pdfMeasures(orientation).margin_right),
      },
      width2fr: {
        x0: pdfMeasures(orientation).margin_left,
        x1:
          pdfMeasures(orientation).margin_left +
          (pdfMeasures(orientation).page_width -
            (pdfMeasures(orientation).margin_left +
              pdfMeasures(orientation).margin_right)) /
            2,
        width:
          (pdfMeasures(orientation).page_width -
            (pdfMeasures(orientation).margin_left +
              pdfMeasures(orientation).margin_right)) /
          2,
      },
      width3fr: {
        x0: pdfMeasures(orientation).margin_left,
        x1:
          pdfMeasures(orientation).margin_left +
          (1 *
            (pdfMeasures(orientation).page_width -
              (pdfMeasures(orientation).margin_left +
                pdfMeasures(orientation).margin_right))) /
            3,
        x2:
          pdfMeasures(orientation).margin_left +
          (2 *
            (pdfMeasures(orientation).page_width -
              (pdfMeasures(orientation).margin_left +
                pdfMeasures(orientation).margin_right))) /
            3,
        width:
          (pdfMeasures(orientation).page_width -
            (pdfMeasures(orientation).margin_left +
              pdfMeasures(orientation).margin_right)) /
          3,
      },
    };
  },
  test: "_this_is_deleted_on_init_",
  init: (state: IPdfState, objectData = {}) => {
    state.clear();
    const key = nanoid(5);
    // init Y Position
    state[key] = { ...objectData, Y: { _: 0 }, warnSignal: undefined };
    return key;
  },
  clear: function (state: IPdfState, payload = null) {
    if (payload) {
      state[payload] = undefined;
      return;
    }
    Object["entries"](state).map(([k, v]) => {
      if (!["persistent", "s"].includes(k) && typeof v !== "function") {
        state[k] = undefined;
      }
    });
  },
  log: (state: IPdfState, k: string) =>
    k ? console.log(state?.[k]) : console.log(state),
  logs: (state: IPdfState, k: string) =>
    console.log(state.s((state?.[k] as ReportData)?.orientation)),
  setY: (
    state: IPdfState,
    { pdfKey, section, y }: { pdfKey: string; section: string; y: number }
  ) => {
    if (!(pdfKey && section && y)) {
      return;
    }
    _.set(state, [pdfKey, "Y", `_${section}`], y);
  },
  getNextY: (
    state: IPdfState,
    pdfKey: string,
    datakey: number,
    defY: number
  ) => {
    // calculates the max Y of the first header row.
    try {
      if (+datakey >= 10) {
        // get max of zero
        const obj0 = Object["entries"]((state[pdfKey] as ReportData)?.Y);
        const row0_0 = obj0.filter(([k]) => parSafe(k) >= 0 && parSafe(k) < 10);
        const row0_1 = row0_0.map(([, v]) => v);
        const newY = row0_1.reduce((a, c) => ((c || 0) > a ? c || 0 : a), 0);
        return newY ? (newY as number) + 10 : defY; /// do not accept 0 as start position for Y on second row.
      }
      // else return default
      return defY;
    } catch (e) {
      return defY;
    }
  },
  // reading the max postition of Y (overall);
  getMaxY: (state: IPdfState, _pdfKey: string) => {
    const pdfKey =
      typeof _pdfKey === "object"
        ? Object.hasOwn(_pdfKey, "pdfKey")
          ? _pdfKey["pdfKey"]
          : null
        : _pdfKey;
    if (pdfKey === null) {
      return 0;
    }
    try {
      return Object["values"]((state[pdfKey] as ReportData)?.Y).reduce(
        (a, c) => ((c || 0) > a ? c || 0 : a),
        0
      );
    } catch (e) {
      console.log("max height pdf calc errror ", e);
      return 0;
    }
  },
  setline: (
    state: IPdfState,
    { pdfKey, k, v }: { pdfKey: string; k: string; v: number }
  ) => {
    if (!(pdfKey && k && v)) {
      return;
    }
    _.set(state, [pdfKey, "line", `_${state.persistent.lineGen++}`], v);
  },
  getlines: (state: IPdfState, _pdfKey: string) => {
    const pdfKey =
      typeof _pdfKey === "object"
        ? Object.hasOwn(_pdfKey, "pdfKey")
          ? _pdfKey["pdfKey"]
          : null
        : _pdfKey;
    if (pdfKey === null) {
      return 0;
    }
    return Object["values"]((state[pdfKey] as ReportData)?.["line"]);
  },
  drawlines: (state: IPdfState, pdfKey: string, doc: any) => {
    for (const o of Object["values"]((state[pdfKey] as ReportData)?.["line"])) {
      const { lc, line, lw } = o as LineData;
      doc.setLineWidth(lw);
      doc.setDrawColor(lc);
      doc.line(line[0], line[1], line[2], line[3]);
    }
  },
  fullLine: (state: IPdfState, pdfKey: string, doc: any) => {
    const y_bg = state.getMaxY(pdfKey) + 4.5;
    const x_bg = transformUnits(margins.left);
    const pWidth =
      doc.internal.pageSize.getWidth() - 0.5 * transformUnits(margins.left);
    doc.line(x_bg, y_bg, pWidth, y_bg);
    state.setY({ pdfKey: pdfKey, section: "line", y: y_bg });
  },
  mainParams: (state: IPdfState, pdfKey: string) => {
    const {
      thisCurrency = "",
      thisLanguage = "en",
      logo,
    } = state[pdfKey] as ReportData;
    return {
      thisCurrency: thisCurrency,
      thisLanguage: thisLanguage,
      logo: logo,
    };
  },
  warnSignal(state: IPdfState, pdfKey: string, msg: string) {
    _.set(state, [pdfKey, "warnSignal"], msg);
  },
  getWarnSignal(state: IPdfState, pdfKey: string) {
    return (state[pdfKey] as ReportData)?.warnSignal;
  },
  clearWarnSignal(state: IPdfState, pdfKey: string) {
    _.set(state, [pdfKey, "warnSignal"], undefined);
  },
});

/* ------------------------ helper functions for pdf ------------------------ */

function ensureArray(ar: any) {
  return Array.isArray(ar) ? ar : [ar].filter((f) => f !== undefined);
}

export const transformUnits = (x: number) => x * 0.2645833333; //px=>mm

export const isAnySection = (sec: any) =>
  Boolean(
    sec &&
      typeof sec === "object" &&
      sec !== null &&
      Reflect.has(sec, "__sectionId")
  );

export function prcX2Int(prc: number | string, pWidth: number) {
  if (!prc) {
    return 0;
  }

  if (typeof prc === "number") {
    return prc;
  }
  try {
    return (+prc.replace(/%/g, "") * pWidth) / 100;
  } catch (e) {
    console.log(`invalid x coordonate ${prc} #1227:`, e);
    return 0; //
  }
}
// transforms "3px" to pixels (3*0.2645833333)
export function justPx2Page(px: number | string) {
  if (typeof px === "number") {
    return px;
  }
  return transformUnits(+px.replace(/px/g, ""));
}

export function px2mm(px: number | string) {
  if (typeof px === "number") {
    return px;
  }
  return transformUnits(+px.replace(/px/g, ""));
}

export function pxInSection2IntInPage(pxdim: any, parentStarts = margins.left) {
  if (typeof parentStarts !== "number") {
    console.log(`invalid coordonate ${parentStarts} #1233:`);
    parentStarts = 0;
  }
  if (typeof pxdim === "number") {
    return transformUnits(pxdim + parentStarts);
  }
  try {
    return transformUnits(+pxdim.replace(/px/g, "") + parentStarts);
  } catch (e) {
    console.log(`invalid coordonate ${pxdim} #1235:`, e);
    return 0; //
  }
}
//transforms common units to pixels (mm=>px) keeping the same ratio
export function width2Page(datawidth: number | string, pWidth: any) {
  if (`${datawidth}` === "page") {
    return pWidth; //( minus margins ?)
  }
  if (`${datawidth}`.includes("%")) {
    if (`${datawidth}` === "33.33%") {
      return pWidth / 3;
    }
    // fallback, other prc
    return prcX2Int(datawidth, pWidth);
  }
  if (`${datawidth}`.includes("px")) {
    return pxInSection2IntInPage(datawidth);
  }
}

export const roDate = (date?: Date) =>
  format(date ? new Date(date) : new Date(), "d LLLL yyyy", {
    locale: dateLocales["ro"],
  });
