import type { ReportData } from "./pdf_state";
import jsPDF from "jspdf";

import {
  pdfState,
  transformUnits,
  width2Page,
  margins,
  justPx2Page,
  debugColor,
  whiteColor,
  silverColor,
} from "./pdf_state";

// this is a simple silver line at current cursor position
export function drawALine(pdf: jsPDF, pdfKey: string) {
  const orientation = (pdfState[pdfKey] as ReportData).orientation;
  pdf.setDrawColor(silverColor);
  (pdf as any).line(
    pdfState.s(orientation).xStartLine,
    pdfState.getMaxY(pdfKey) + 4.5,
    pdfState.s(orientation).xEndLine,
    pdfState.getMaxY(pdfKey) + 4.5
  );
  pdfState.setY({
    pdfKey: pdfKey,
    section: "line",
    y: pdfState.getMaxY(pdfKey) + 4.5,
  });
  return pdf;
}
