import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import type dxDataGrid from "devextreme/ui/data_grid";
import "jspdf-autotable";
import { truncate, sum } from "lodash";
import { pdfState } from "./pdf_state";

function formatStandard(options: any) {
  if (options.gridCell.rowType === "group") {
    options.pdfCell.backgroundColor = "#f7f7f7";
    options.pdfCell.font.name = "Roboto-Bold";
    options.pdfCell.font.size = 7;
  } else {
    options.pdfCell.font.name = "Roboto-Regular";
    options.pdfCell.font.size = 7;
  }
  if (options.gridCell.rowType === "totalFooter") {
    options.pdfCell.font.name = "Roboto-Bold";
    options.pdfCell.font.size = 7;
  }
}
export const addTimeSheet0 = async (pdf: any, key: string, objectData: any) => {
  pdf.setFont("Roboto-Regular", "normal");
  pdf.setFontSize(7);
  await exportDataGridToPdf({
    jsPDFDocument: pdf,
    component: objectData,
    topLeft: { x: 0, y: pdfState.getMaxY(key) },
    customizeCell(options) {
      formatStandard(options);
    },
  });
  return pdf;
};

/**
 * Call the exportDataGridToPdf from devextreme/pdf_exporter
 * It also truncates the text if it's too long for given column
 *
 * @param {*} pdf
 * @param {*} key
 * @param {*} objectData
 * @returns an instance of jsPDF
 */
export const addAnyGrid = async (pdf: any, key: string, objectData: any) => {
  const testObj = {
    break: false,
    fieldCount: 0,
    colWidth: [],
    avgWidth: 0,
    avgHeight: 0,
    msg1: "",
    msg2: "",
  };
  // [##findme-217##]
  const enabledTrucation = (
    objectData?._$element?.[0]?.className || ""
  ).includes("dx-truncated-text");
  pdf.setFont("Roboto-Regular", "normal");
  pdf.setFontSize(7);
  const ph = pdf.internal.pageSize.height;
  await exportDataGridToPdf({
    jsPDFDocument: pdf,
    component: objectData,
    topLeft: { x: 0, y: pdfState.getMaxY(key) },
    repeatHeaders: true,
    customizeCell(options: any) {
      if (enabledTrucation && options.gridCell.column.dataField === "ECHIPA") {
        if (options.gridCell.column.cssClass === "dx-field-long-text") {
          const trc = truncate(options.pdfCell.text, {
            length: 30,
            separator: " ",
          });
          options.gridCell.value = trc;
          options.pdfCell.text = trc;
        }
      }
      formatStandard(options);
    },
    // customDrawCell(options: any) {
    //   if (options.gridCell.rowType === "header" && testObj.break === false) {
    //     testObj.fieldCount++;
    //     testObj.colWidth.push(options.rect.w);
    //     testObj.avgWidth = sum(testObj.colWidth) / testObj.fieldCount;
    //     testObj.avgHeight = options.rect.h;
    //   }
    //   if (options.gridCell.rowType === "data" && testObj.break === false) {
    //     testObj.break = true;
    //     if (testObj.fieldCount > 5 && testObj.avgHeight > testObj.avgWidth) {
    //       testObj.msg1 = `<span>Documentul pare sa aiba un format neprietenos pentru exportul in PDF.</span>
    //         </br>
    //         Sugestia este sa anulati exportul si sa refaceti formatul, sau sa exportati in Excel.
    //         </br>
    //         `;
    //     }
    //   }
    //   /* -------------------------------------------------------------------------- */
    //   // this doesn't work. It calculates that the cell is too big for the page
    //   // but it doesn't truncate the text...
    //   if (options.rect.h + testObj.avgHeight > ph) {
    //     // options.cancel = true;
    //     testObj.msg1 = `<span>Documentul are un camp care se intinde pe mai multe pagini. Am sa il trunchez</span></br>`;
    //     const trc = truncate(options.pdfCell.text, {
    //       length: 300,
    //       separator: " ",
    //     });
    //     options.pdfCell.text = trc + "...[textul a fost trunchiat]";
    //     options.rect.h = 100;
    //     console.log("options", options);
    //   }
    //   /* -------------------------------------------------------------------------- */
    // },
  });
  const p = pdf.internal.getNumberOfPages();
  if (p > 30) {
    testObj.msg2 = `<span>Documentul generat va avea ${p} pagini.</span></br>`;
  }
  if (testObj.msg1 || testObj.msg2) {
    pdfState.warnSignal(
      key,
      `${testObj.msg1 || ""} ${
        testObj.msg2 || ""
      } </br><b>Continuati generarea raportului?<b>`
    );
  }
  return pdf;
};

export const addMasterDetailGrid = async (
  pdf: any,
  key: string,
  objectData: any,
  details: any
) => {
  // TODO: it's difficult. There is no support for master-detail grid in pdf_exporter
  // neither in jspdf-autotable
  // so, all the mappings and measures has to be done manually
  // maybe later... [##findme:236##]
  return pdf;
};
