import { createAssign, createTable } from "robodux";
import { put, call, spawn } from "saga-query";
import { showLoading, hideLoading } from "react-redux-loading-bar";

import { api, thunks } from "@app/state/apis/api";
import { loadWorkData } from "./workdata";

import type { RootState } from "../rootState";
import type { ApiCtx, SagaIterator } from "saga-query";
import type { MapEntity } from "robodux";
import type {
  IDBOrgType,
  IDBBranch,
  IDBResource,
  IDBSession,
} from "@app/types";
import { createSelector } from "reselect";

type TUid = { UID: number };

const REPO_ORGTYPES = "orgtypes";
const REPO_BRACNCHES = "branches";
const REPO_WSESSIONS = "sessions"; // work sessions (seasons)
const REPO_RESOURCES = "resources";

export const orgRepo = createTable<IDBOrgType>({
  name: REPO_ORGTYPES,
  initialState: {
    1: { OTYID: 1, ORG_TYPE_NAME: "Filiale/Organizații teritoriale ale UGIR" },
    2: {
      OTYID: 2,
      ORG_TYPE_NAME: "Federații, patronate, organizații patronale",
    },
  },
});

export const branchRepo = createTable<IDBBranch>({
  name: REPO_BRACNCHES,
  initialState: {},
});

export const sessionRepo = createTable<IDBSession>({
  name: REPO_WSESSIONS,
  initialState: {},
});

export const resourceRepo = createTable<IDBResource>({
  name: REPO_RESOURCES,
  initialState: {},
});

export const loadOrgTypes = api.get(
  "/structure/organizations",
  function* (ctx: ApiCtx<any, any, any>, next) {
    ctx.cache = false;
    ctx.request = ctx.req({
      url: `/structure/organizations`,
    });
    yield next();
    const { data, ok } = yield ctx.json;
    const formatted: Record<string, IDBOrgType> = data.reduce(
      (acc: Record<string, IDBOrgType>, item: IDBOrgType) => {
        acc[item.OTYID] = item;
        return acc;
      },
      {}
    );
    yield* put(orgRepo.actions.set(formatted));
  }
);

export const loadBranches = api.get(
  "/structure/branches",
  function* (ctx: ApiCtx<any, any, any>, next) {
    ctx.cache = false;
    ctx.request = ctx.req({
      url: `/structure/branches`,
    });
    yield next();
    const { data, ok } = yield ctx.json;
    const formatted = data.reduce(
      (acc: Record<string, IDBBranch>, item: IDBBranch) => {
        acc[item.BID] = item;
        return acc;
      },
      {}
    ) as Record<string, IDBBranch>;
    yield* put(branchRepo.actions.set(formatted));
  }
);

export const loadSessions = api.get(
  "/structure/worksessions",
  function* (ctx: ApiCtx<any, any, any>, next) {
    ctx.cache = false;
    ctx.request = ctx.req({
      url: `/structure/worksessions`,
    });
    yield next();
    const { data } = yield ctx.json;
    const formatted = data.reduce(
      (acc: Record<string, IDBSession>, item: IDBSession) => {
        acc[item.SID] = item;
        return acc;
      },
      {}
    );
    yield* put(sessionRepo.actions.set(formatted));
    //  const maxSID = Math.max(...data.map((item:IDBSession) => item.SID)); //?
  }
);

export const loadResources = api.get(
  "/structure/resources",
  function* (ctx: ApiCtx<any, IDBResource[], any>, next) {
    ctx.cache = false;
    ctx.request = ctx.req({
      url: `/structure/resources`,
    });
    yield next();
    const { data } = yield ctx.json;
    const formatted = data.reduce(
      (acc: Record<string, IDBResource>, item: IDBResource) => {
        acc[item.RSID] = item;
        return acc;
      },
      {}
    );
    yield* put(resourceRepo.actions.set(formatted));
  }
);

export const runInitialLoad = thunks.create<TUid>(
  "/structure/initial",
  function* initStructures(ctx, next) {
    yield* put(showLoading());
    yield* call(loadOrgTypes.run, loadOrgTypes());
    yield* call(loadBranches.run, loadBranches());
    yield* call(loadSessions.run, loadSessions());
    yield* call(loadResources.run, loadResources());
    // yield* call(loadAQCachedNames.run, loadAQCachedNames());
    yield* call(loadWorkData.run, loadWorkData());
    yield* put(hideLoading());
    yield next();
  }
);

const orgTypeSelectors = orgRepo?.getSelectors(
  (state: RootState) => state?.[REPO_ORGTYPES]
);
export const selectOrgTypes_ = (s: RootState) =>
  orgTypeSelectors?.selectTableAsList(s);

export const selectOrgTypesArr = createSelector(
  selectOrgTypes_,
  (orgTypes): IDBOrgType[] => orgTypes
);

export const selectOrgTypesO_ = (s: RootState) =>
  orgTypeSelectors?.selectTable(s);

export const selectOrgTypesKby = createSelector(
  selectOrgTypesO_,
  (orgTypes): MapEntity<IDBOrgType> => orgTypes
);

const branchSelectors = branchRepo.getSelectors(
  (state: RootState) => state?.[REPO_BRACNCHES]
);

const selectBranches_ = (s: RootState) => branchSelectors.selectTable(s);
export const selectBranchesKby = createSelector(
  selectBranches_,
  (branches) => branches
);

const sessionSelectors = sessionRepo.getSelectors(
  (state: RootState) => state?.[REPO_WSESSIONS]
);

export const selectSessions = (s: RootState) =>
  sessionSelectors.selectTable(s) as MapEntity<IDBSession>;
const selectSessionsList_ = (s: RootState) =>
  sessionSelectors.selectTableAsList(s);
export const selectSessionsList = createSelector(
  selectSessionsList_,
  (sessions) => sessions
);

const selectSessionKeyBy_ = (s: RootState) =>
  sessionSelectors.selectTable(s) as MapEntity<IDBSession>;
export const selectSessionKeyBy = createSelector(
  selectSessionKeyBy_,
  (sessions) => sessions
);
const resourceSelectors = resourceRepo.getSelectors(
  (state: RootState) => state?.[REPO_RESOURCES]
);
const selectResources_ = (s: RootState) => resourceSelectors.selectTable(s);

export const selectResources = createSelector(
  selectResources_,
  (resources) => resources
);
