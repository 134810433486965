import { prepareStore } from "saga-query";
import { createReducerMap } from "robodux";
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar";

import { api, thunks } from "./api";
import { loginRepo } from "../units/login";
import { uiRepo } from "../units/ui";
import { workDataRepo, workDataLayoutRepo } from "../units/workdata";
import { anafRepo } from "../units/anaf";

import {
  orgRepo,
  branchRepo,
  sessionRepo,
  resourceRepo,
} from "../units/structures";
// import { sagas } from "../resetStore";
import rootSaga from "../rootSaga";

export const reducers = createReducerMap(
  anafRepo,
  branchRepo,
  loginRepo,
  orgRepo,
  resourceRepo,
  sessionRepo,
  uiRepo,
  workDataLayoutRepo,
  workDataRepo,
  { name: "loadingBar", reducer: loadingBar }
);

export const apiStore = prepareStore({
  reducers,
  sagas: {
    0: api.saga(),
    1: thunks.saga(),
    2: rootSaga,
  },
});

export { api, thunks } from "./api";
export const rootReducer = apiStore.reducer;
export type RootReducer = ReturnType<typeof rootReducer>;
