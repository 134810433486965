import React from "react";
//ALLFORMS:
//https://supportcenter.devexpress.com/ticket/details/t729763/textbox-how-to-disable-the-google-chrome-auto-fill-feature-on-the-application-level#
//
import "./commonForms.scss";
function _onenterkey(e: any) {
  // do not change if not sure
  const inputs = document.getElementsByTagName("INPUT") as any;
  // const thisName = e.event.srcElement.name;
  const thisName =
    e?.event?.srcElement?.name || e?.event?.originalEvent?.srcElement?.name;
  // console.log('this', this)
  for (let index = 0; index < inputs.length; ++index) {
    if (thisName === inputs[index]?.["name"]) {
      const ctrl = inputs[index];
      //get next
      const ctrlNext = inputs?.[index + 1];
      if (ctrlNext) {
        try {
          ctrlNext?.["focus"]();
          // sic:
          setTimeout(function () {
            ctrlNext?.["focus"]();
          }, 2);
        } catch (e) {
          console.log("zebra-helper #1209", e);
          setTimeout(function () {
            ctrlNext?.["focus"]();
          }, 2);
        }
      }
    }
  }
}
// //
// function _onKeyDown(e) {
//   return;
//   //event.originalEvent.keyCode
//   // eslint-disable-next-line no-unreachable
//   if (e.event.keyCode === 13) {
//     // do not change if not sure / nu schimba daca nu esti sigur...
//     const inputs = document.getElementsByTagName('input');
//     console.log('inputs', inputs)
//     for (let index = 0; index < inputs.length; ++index) {
//       if (e.event.srcElement.name === inputs[index].name) {
//         //get next
//         // blur:
//         inputs[index + 1]?.focus();
//         // wait for set state...
//         setTimeout(function () { inputs[index + 1].focus() }, 2);
//       }
//     }
//   }
// }
//...
// function _onValueChanged(e) {
//   e.component.focus();
// }
//...
function _onfocus(e: any) {
  if (!(e?.event?.srcElement?.id || e?.event?.originalEvent?.srcElement?.id)) {
    return;
  }
  const theId =
    e?.event?.srcElement?.id || e?.event?.originalEvent?.srcElement?.id;
  const theLabel = document.querySelector(`label[for=${theId}]`);
  theLabel?.classList?.add("commonstyle-selectedLabel");
}
//...
function _onblur(e: any) {
  if (!(e?.event?.srcElement?.id || e?.event?.originalEvent?.srcElement?.id)) {
    return;
  }
  const theId =
    e?.event?.srcElement?.id || e?.event?.originalEvent?.srcElement?.id;
  const theLabel = document.querySelector(`label[for="${theId}"]`);
  theLabel?.classList?.remove("commonstyle-selectedLabel");
}
//...

export const commonSimpleStyle = ({
  elementclass = "",
  inputclass = "",
} = {}) => ({
  mode: "text",
  stylingMode: "outlined",
  elementAttr: {
    autoComplete: "new",
    class: `commonstyle-element ${elementclass}`,
  },
  inputAttr: {
    lang: "ro",
    type: "text",
    autoComplete: "off",
    // autoComplete:"new", // <- sometimes this works better
    class: `commonstyle-input ${inputclass}`,
  },
});

//..
export const commonStyle = ({ elementclass = "", inputclass = "" } = {}) => ({
  ...commonSimpleStyle({ elementclass: elementclass, inputclass: inputclass }),
  valueChangeEvent: "blur",
  onFocusIn: _onfocus,
  onFocusOut: _onblur,
  // onValueChanged: _onValueChanged,
  onEnterKey: _onenterkey,
  // onKeyDown: _onKeyDown,
});
