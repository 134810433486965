import React from "react";
import ReactDOM from "react-dom/client";
import { Store } from "./state/rootState";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./index.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
    authorizationParams={{
      max_age: 60 * 60,
      redirect_uri:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3001"
          : "https://ugir.jurnalgold.ro",
      response_type: "id_token",
      response_mode: "form_post",
      nonce: "NONCE",
      ui_locales: "ro",
    }}
  >
    <Store>
      <App />
    </Store>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
