import { IPdfState, pdfState } from "./pdf_state";

export const addPageNo = async (pdf: any, pdfKey: string) => {
  const pages = pdf.internal.getNumberOfPages();
  const pageWidth = pdf.internal.pageSize.width; //Optional
  const pageHeight = pdf.internal.pageSize.height; //Optional
  pdf.setFontSize(7); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2; //Can be fixed number
    const verticalPos = pageHeight - 10; //Can be fixed number
    pdf.setPage(j);
    pdf.text(`${j} din ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  return pdf;
};
