import {
  createApi,
  fetcher,
  requestMonitor,
  createPipe,
  errorHandler,
  dispatchActions,
  ApiCtx,
  Next,
} from "saga-query";
import type { PipeCtx, LoaderCtx } from "saga-query";

import notify from "devextreme/ui/notify";
import { IAppNotify } from "@app/types";

import { service } from "@app/service";
import type { Action } from "@app/types";

export interface ThunkCtx<P = any, D = any> extends PipeCtx<P>, LoaderCtx<P> {
  actions: Action[];
  json: D | null;
}

export const thunks = createPipe<ThunkCtx>();
thunks.use(errorHandler);
thunks.use(function* (ctx, next) {
  ctx.json = null;
  yield next();
});
thunks.use(dispatchActions);
thunks.use(thunks.routes());

const errMessageMiddleware = function* (ctx: ApiCtx, next: Next) {
  yield next();
  const { ok, status, statusText } = yield ctx.response;
  if (!ok) {
    yield notify(
      {
        message: `${status} ${statusText}`,
        width: 400,
        position: {
          at: "top",
          my: "top",
          of: "#root",
          offset: "0 22",
        },
      },
      "error",
      5000
    );
  }
};

export const api = createApi();
api.use(requestMonitor());
api.use(api.routes());
api.use(errMessageMiddleware); // not sure if this is the right place //(?)//
api.use(fetcher({ baseUrl: service }));
