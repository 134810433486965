import React, { useCallback, useState } from "react";
import { useAppSelector } from "@app/state";
import Drawer from "devextreme-react/drawer";
import { Switch, Case, CaseElse } from "@app/contexts";
import { DrawerInside } from "@app/components";
import WorkDataMain from "@app/pages/workdata/WorkData";
import { useWindowDimensions } from "@app/contexts";
import { uiCurrentPageSelector } from "@app/state/units/ui";
import { TUIPageName } from "@app/types";

const YesAuth = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const { height: fullHeight } = useWindowDimensions();
  const currentPage = useAppSelector<TUIPageName>(uiCurrentPageSelector);
  const toggleDrawer = useCallback(() => {
    setDrawerOpened((p) => !p);
  }, [drawerOpened]);

  return (
    <div className="authenticated">
      <Drawer
        opened={drawerOpened}
        openedStateMode={"overlap"}
        position={"left"}
        revealMode={"slide"}
        render={() => <DrawerInside toggleDrawer={toggleDrawer} />}
        closeOnOutsideClick={false}
        height={(fullHeight || 0) - 4}
        minSize={66}
      >
        <div id={"importantwrapper"}>
          <Switch value={currentPage}>
            <Case
              when={(x: TUIPageName) =>
                ["workdata/any", "workdata/sq"].includes(x)
              }
              mounted
            >
              <WorkDataMain currentPage={currentPage} />
            </Case>
            <Case when={"resources"}>
              <div className="resources">{"resources"}</div>
            </Case>
            <CaseElse>
              <div className="not-found">{""}</div>
            </CaseElse>
          </Switch>
        </div>
      </Drawer>
    </div>
  );
};

export default YesAuth;
