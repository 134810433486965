import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  memo,
} from "react";
import { useAppSelector, useAppDispatch } from "../../state";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import type { Operation } from "effection";
import { run, sleep, withLabels, ensure } from "effection";
import levenshtein from "js-levenshtein";
/* ---------------------------------- devex --------------------------------- */
import DataGrid, {
  Column,
  FilterRow,
  LoadPanel,
  Sorting,
  Selection,
  Grouping,
  GroupPanel,
  HeaderFilter,
  ColumnChooser,
  ColumnFixing,
  Export,
  FilterPanel,
  Editing,
  StateStoring,
  Toolbar,
  Item,
  Paging,
  Scrolling,
  Summary,
  TotalItem,
  GroupItem,
  SortByGroupSummaryInfo,
  Button,
  Form,
  Popup,
  Label,
} from "devextreme-react/data-grid";
import {
  Item as FormItem,
  ButtonItem,
  GroupItem as FormGroupItem,
  SimpleItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from "devextreme-react/form";
import "devextreme-react/text-area";
//excel
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import DataSource from "devextreme/data/data_source";

import CustomStore from "devextreme/data/custom_store";
import TextBox from "devextreme-react/text-box";
/* helpers  */
import size from "lodash/fp/size";
import pick from "lodash/fp/pick";
// import _ from "lodash";
import {
  filter as jscFilter,
  singleton,
  yielding,
  uniqueBy,
} from "js-coroutines";
import resso from "resso";
import sum from "hash-sum";
/* ----------------------------------- our stuff ----------------------------------- */
import { service } from "@app/service";
import { useWindowDimensions, usePdfContext } from "@app/contexts";
import {
  // anafQuery,
  workDataLayoutStateSelector,
  slectListReportLoadedID,
  listSwitchSelector,
  currentListSaveEdit,
  currentListSaveModel,
  currentListDelete,
  selectOrgTypesArr,
  appNotify,
} from "@app/state/units";
import { GRID_LAYOUTS } from "@app/state/constants";

import {
  fieldDefs,
  mark,
  markRegexParts,
  md,
  parts,
  regexParts,
  SEARCH_MIN_CHARS_NUMBER,
  DOMPurify,
} from "./lxr_common_definitions";

import {
  exportToPdfOpts,
  columnChooserButtonOpts,
  searchBoxOpts,
  groupCustomTotals,
  groupCustomItem,
  calculateWithDistinct,
  sessionSelectOpts,
  saveStateButtonOpts,
  showDupsGroupOpts,
  layoutButtonGroupOpts,
  LabelAnaf,
  groupSum,
} from "./lxr_common_elements";
import { IListState, TListOriginName } from "@app/types";
//style
import "@app/style/dx.generic.teamZebra.css";
import "@app/style/dx.generic.jurnalKarma1.css";
import "./grids_common.scss";
//local types
import type { IListRecord } from "@app/types";

/* -------------------------------------------------------------------------- */
/*                                  THE GRID                                  */
/* -------------------------------------------------------------------------- */

type TWhichState = "initial" | "current" | IListState;
type TLayoutId = 0 | 1 | 2;
type TCriteria = {
  searchString: string;
  dupState?: "all" | "duplicates" | "unique";
};

type TRessoLocal = {
  expectedGridState: any;
  xrender: any;
  changes: any[];
  editRowKey: string | null;
  isLoading: boolean;
};
const dupStates: Record<string, TCriteria["dupState"]> = {
  0: "all",
  1: "unique",
  2: "duplicates",
};

const local = resso<TRessoLocal>({
  expectedGridState: {},
  xrender: null,
  changes: [],
  editRowKey: "",
  isLoading: false,
});

// let rowIndex = -1;

const AnyGrid = ({ listOrigin }: { listOrigin: TListOriginName }) => {
  const dispatch = useAppDispatch();
  const { expectedGridState } = local;
  const effRef5 = useRef<boolean>(false);
  const savedStateRef = useRef<IListState | null | undefined>(null);
  const searchStringRef = useRef<string>();
  const tsSearchBoxRef = useRef<TextBox>(null);
  const loadedColumnsRef = useRef<string[]>([]);
  const rowIndexRef = useRef<number>(-1);
  const [dataSet, setDataset] = useState<IListRecord[]>([]);
  const [layoutID, setLayoutID] = useState<TLayoutId>(0);
  const [fieldDefinitions, setFieldDefinitions] = useState<{
    [key: string]: any;
  }>({});
  const [tsSrchBoxValue, setTsSearchValue] = useState<string>("");
  const [dupStateState, setDupState] = useState<TCriteria["dupState"]>("all");
  const theState = useAppSelector(workDataLayoutStateSelector);
  const theLoadedListID = useAppSelector(slectListReportLoadedID);
  const theData = useAppSelector((s) =>
    listSwitchSelector(s, { list: listOrigin })
  );
  const { anyListGridRef: tsGridRef, openPdf, friendlyName } = usePdfContext();
  const { t } = useTranslation();
  const { height: fullHeight } = useWindowDimensions();
  const fieldsLoadedDependency = useMemo(
    () => `${listOrigin}-${JSON.stringify(Object.keys(dataSet?.[0] || {}))}`,
    [`${listOrigin}-${JSON.stringify(Object.keys(dataSet?.[0] || {}))}`]
  );
  const theFrmOrgTypes = useAppSelector(selectOrgTypesArr);
  const dataCrc = useMemo(() => sum(dataSet), [dataSet]);

  const eUpdateRecord = ({
    key,
    values,
  }: {
    key: { WDI: number };
    values: Partial<IListRecord>;
  }) =>
    function* (): Operation<boolean> {
      yield eStartUpdate();
      const crtState2 = yield eSaveNextExpected("current");
      dispatch(
        currentListSaveModel({ listOrigin: listOrigin, gridState: crtState2 })
      );
      const uObj = { ...key, ...values };
      dispatch(currentListSaveEdit({ listOrigin, dataset: uObj }));
      yield eApplyExpectedState();
      yield sleep(500); // imp //
      yield ensure(function* () {
        yield eEndUpdate();
      });
      return true;
    };

  const eWaitForSettle = () => {
    return function* (): Operation<void> {
      yield ensure(function* () {
        yield sleep(1);
        yield eEndUpdate();
      });
      return true;
    };
  };
  // issue: #51
  // for this kind of filtering and render, the DataSource
  // is ok to live inside the component.'
  // not sure if it's optimal, but it should generate render only when needed
  //
  // also is interesting to investigate what is the optimal position
  // of this piece of code (DataSource), inside the component or outside,
  // at the beginning or at the end, etc.
  const anyPlainDataSource = useMemo(
    () =>
      new DataSource({
        store: new CustomStore({
          loadMode: "raw",
          cacheRawData: true,
          key: fieldDefs[`_KEYOF_TABLE_${listOrigin}`],
          load: () => {
            if (!size(dataSet)) {
              return [];
            }
            return dataSet;
          },
          update: (key, values) => {
            // const uRecord =
            run(eUpdateRecord({ key, values }));
            return { ...key, ...values };
          },
          remove: (key) => {
            dispatch(currentListDelete({ listOrigin, WDI: key.WDI }));
            return key;
          },
        }),
        paginate: false,
      }),
    [dataCrc, listOrigin, layoutID, tsSrchBoxValue, dupStateState]
  );

  const memoClassName = useMemo(() => {
    return `dx-swatch-jurnalKarma1 anyGrid ${
      !size(dataSet) && tsSrchBoxValue ? "spdanger" : ""
    }`;
  }, [size(dataSet), tsSrchBoxValue]);

  function* eInitializeColumns(): Operation<void> {
    yield run(eLoadColumns());
    //we need to refresh the fieldDefinitions on load by setting them into the state
    //tryed to use the loadedColumnsRef.current but it was not working as expected
    const thisFieldDefs = pick(loadedColumnsRef.current, fieldDefs);
    setFieldDefinitions(thisFieldDefs);
    return;
  }
  //eslint-disable-next-line require-yield
  function* eSetLayout(specificLayoutID?: number): Operation<void> {
    // on initial load we need to set the layoutID
    if (specificLayoutID === undefined) {
      const o = Object.assign({}, JSON.parse(JSON.stringify(theState)));
      specificLayoutID = o?.["layoutID"] ?? 2;
    }
    // we load the layoutIDz
    if ([0, 1, 2].includes(specificLayoutID ?? 2)) {
      if (layoutID !== specificLayoutID) {
        const s = specificLayoutID ?? 2;
        console.log("s--->>>", s);
        setLayoutID(s as TLayoutId);
      }
    } else {
      //fallback to fullscreen
      setLayoutID(2);
    }
    return "done";
  }
  //eslint-disable-next-line require-yield
  function* eLoadColumns(): Operation<void> {
    // it doesn't matter the state instance.
    let fieldList = Object.keys(dataSet?.[0] || {});
    if (!fieldList.length) {
      fieldList = Object.keys(theData?.[0] || {});
      if (!fieldList.length) {
        return "failed";
      }
    }
    loadedColumnsRef.current = fieldList;
    return "done";
  }
  //eslint-disable-next-line require-yield
  function* eSetGridStateRef(nextState: IListState): Operation<void> {
    if (Object.hasOwn(nextState, "current")) {
      // ERROR ON SERIALIZATION
      delete (nextState as any)["current"];
      console.log(
        "Found error on nextState. Nested refs. This should not happen!!!(#0411)"
      );
    }
    if (tsGridRef?.current) {
      tsGridRef.current.instance.state(nextState);
    }
    return "ok";
  }
  //eslint-disable-next-line require-yield
  function* eStartUpdate(): Operation<string> {
    if (tsGridRef?.current) {
      tsGridRef.current.instance.beginUpdate();
    }
    return "started";
  }
  //eslint-disable-next-line require-yield
  function* eEndUpdate(): Operation<string> {
    if (tsGridRef?.current) {
      tsGridRef.current.instance.endUpdate();
    }
    return "ended";
  }
  //eslint-disable-next-line require-yield
  function* eSaveNextExpected(whichState: TWhichState): Operation<void> {
    let ssc;
    switch (whichState) {
      case "initial":
        ssc = structuredClone(theState as IListState);
        local.expectedGridState = Object.freeze(ssc) as IListState;
        break;
      case "current":
        {
          let currentPage4Layout = structuredClone(savedStateRef.current);
          if (!currentPage4Layout) {
            currentPage4Layout = structuredClone(theState);
          }
          const currentPageLayoutID = currentPage4Layout?.["layoutID"];
          const currentPageLayoutIDFromState = layoutID;
          if (tsGridRef?.current) {
            const ssc_ = Object.assign({}, tsGridRef.current.instance.state(), {
              layoutID:
                layoutID ?? currentPageLayoutIDFromState ?? currentPageLayoutID,
            });
            ssc = structuredClone(ssc_);
          }
        }
        break;
      default:
        {
          if (typeof whichState === "object" && whichState !== null) {
            local.expectedGridState = Object.freeze(whichState) as IListState;
            ssc = structuredClone(whichState);
          }
        }
        break;
    }
    savedStateRef.current = Object.freeze(ssc) as IListState;
    return ssc;
  }
  //eslint-disable-next-line require-yield
  function* eApplyExpectedState(): Operation<void> {
    if (tsGridRef?.current) {
      tsGridRef.current.instance.state(local.expectedGridState);
    }
    return "ok";
  }

  type TofData = typeof theData;
  const filterAllWork = singleton(function* (
    array: TofData,
    fCriteria = {
      searchString: "",
      dupState: "all",
    } as TCriteria
  ): Generator<any, TofData, any> {
    const { searchString, dupState } = fCriteria;
    const ou3 = yield* jscFilter(
      array,
      yielding((item) => {
        if (size(searchString) >= SEARCH_MIN_CHARS_NUMBER) {
          const weSearch = regexParts(parts(searchString));
          return weSearch.some((rp) => {
            const fields = Object.values(item).filter(
              (x) => typeof x === "string"
            ) as string[];
            return fields.some((f: string) => rp.test(f));
          });
        } else {
          return true;
        }
      })
    );

    switch (dupState) {
      case "all":
        return ou3 as TofData;
      case "duplicates": {
        type TACC = { [key: string]: number };
        const countEachId = ou3.reduce((acc: TACC, x: IListRecord) => {
          acc[x.CUI] = (acc[x.CUI] || 0) + 1;
          return acc;
        }, {});
        const ou5 = ou3.filter((x: IListRecord) => {
          return countEachId[x.CUI] > 1;
        });
        return ou5 as TofData;
      }
      case "unique": {
        // return the unique records by CUI but pick the one with the highest number of SALARIATI field
        type TACC = { [key: string]: IListRecord };
        const picker = ou3.reduce((acc: TACC, x: IListRecord) => {
          const cui = x.CUI;
          if (!acc[cui]) {
            acc[cui] = x;
          }
          if (x.SALARIATI > acc[cui].SALARIATI) {
            acc[cui] = x;
          }
          return acc;
        }, {});
        const ou5 = Object.values(picker);
        return ou5 as TofData; // on same raw data: 302396 vs 300386
      }
      default:
        return ou3 as TofData;
    }
  }, theData);

  function* eProcessWorkData(
    array: TofData, //typeof theData| null,
    { searchString, dupState }: TCriteria
  ): Generator<any, TofData, any> {
    if (!array) {
      array = theData;
    }
    let _search = searchString; // || tsSrchBoxValue;
    if (size(_search) < SEARCH_MIN_CHARS_NUMBER) {
      _search = "";
    }
    const _dupState = dupState || dupStateState;
    const ds = yield filterAllWork(array, {
      searchString: _search,
      dupState: _dupState,
    });
    return ds;
  }

  //eslint-disable-next-line require-yield
  function* eSetProcessedData(
    ds: IListRecord[],
    { searchString, dupState }: TCriteria
  ) {
    if (effRef5.current) {
      setTsSearchValue(searchString);
      setDataset(ds);
      searchStringRef.current = searchString;
      if (dupState) {
        setDupState(dupState);
      }
      return true;
    }
    return false;
  }

  const eTApplyGridState = function* (
    whichState: TWhichState
  ): Operation<void> {
    const labels = { fn: "eTApplyGridState" };
    yield withLabels(eStartUpdate(), labels);

    let nextState = whichState
      ? yield eSaveNextExpected(whichState)
      : local.expectedGridState;
    nextState = nextState || {};
    const li = nextState?.["layoutID"] ?? 2;
    yield withLabels(eSetLayout(li), labels);
    yield withLabels(sleep(10), labels);
    yield withLabels(eLoadColumns(), labels);
    yield withLabels(sleep(10), labels);
    yield run(eSetGridStateRef(nextState));
    yield ensure(function* () {
      yield sleep(300);
      yield eEndUpdate();
    });
  };

  const eSearchTask = (text: string) =>
    function* (): Operation<void> {
      yield eStartUpdate();
      yield sleep(10);
      yield eSaveNextExpected("current");
      yield sleep(10);
      const dataset = yield eProcessWorkData(null, { searchString: text });
      yield eSetProcessedData(dataset, { searchString: text });
      yield sleep(10);
      yield eApplyExpectedState();
      yield sleep(500); // imp //
      yield ensure(function* () {
        yield eEndUpdate();
      });
      return;
    };

  const eDuplicateStateTask = (dupState: TCriteria["dupState"]) =>
    function* (): Operation<void> {
      yield eStartUpdate();
      yield sleep(10);
      yield eSaveNextExpected("current");
      yield sleep(10);
      const searchString = tsSrchBoxValue;
      const dataset = yield eProcessWorkData(null, {
        searchString: searchString,
        dupState: dupState,
      });
      yield eSetProcessedData(dataset, {
        searchString: searchString,
        dupState: dupState,
      });
      yield sleep(10);
      yield eApplyExpectedState();
      yield sleep(500); // imp //
      yield ensure(function* () {
        yield eEndUpdate();
      });
      return;
    };

  const eLoadDatasetDbg = function* (): Operation<void> {
    console.log("entering eLoadDataset");
    const labels = { fn: "eLoadDataset" };
    const searchString = tsSrchBoxValue;
    const q1 = yield withLabels(eStartUpdate(), labels);
    console.log("q1", q1);
    const q2 = yield withLabels(eLoadColumns(), labels);
    console.log("q2", q2);
    const q3 = yield withLabels(sleep(10), labels);
    console.log("q3", q3);
    const q4 = yield withLabels(eSetLayout(), labels);
    console.log("q4", q4);
    // essential >
    const dataset = yield eProcessWorkData(null, { searchString });
    console.log("dataset", dataset);
    const q5 = yield eSetProcessedData(dataset, { searchString });
    console.log("q5", q5);
    // essential <
    yield withLabels(sleep(700), labels);
    yield withLabels(eTApplyGridState("initial"), labels);
    yield sleep(10);
    yield ensure(function* (): Operation<string> {
      const c = yield withLabels(eEndUpdate(), labels);
      console.log("c>>", c);
    });
    return;
  };
  const eLoadDataset = function* (): Operation<void> {
    const labels = { fn: "eLoadDataset" };
    const searchString = tsSrchBoxValue;
    yield withLabels(eStartUpdate(), labels);
    yield withLabels(eLoadColumns(), labels);
    yield withLabels(sleep(10), labels);
    yield withLabels(eSetLayout(), labels);
    const dataset = yield eProcessWorkData(null, { searchString });
    yield eSetProcessedData(dataset, { searchString });
    yield withLabels(sleep(700), labels);
    yield withLabels(eTApplyGridState("initial"), labels);
    yield sleep(10);
    yield ensure(function* () {
      yield withLabels(eEndUpdate(), labels);
    });
    return;
  };

  useEffect(() => {
    //0.
    effRef5.current = true;
    return () => {
      //max.important
      savedStateRef.current = undefined;
      effRef5.current = false;
    };
  });

  useEffect(() => {
    //1.
    // important to be before the next useEffect!
    // it renders and will reset the columns state
    run(eSetLayout());
  }, [theLoadedListID]);

  useEffect(() => {
    //2.
    run(eInitializeColumns());
  }, [fieldsLoadedDependency]);

  useEffect(() => {
    // 3.
    run(eLoadDataset());
  }, [theData]);

  useEffect(() => {
    //4.
    if (
      size(dataSet?.[0]) &&
      tsGridRef &&
      tsGridRef.current &&
      !savedStateRef.current &&
      !size(expectedGridState)
    ) {
      run(eTApplyGridState("initial"));
    }
  }, [
    JSON.stringify(dataSet?.[0]),
    JSON.stringify(theState),
    savedStateRef.current,
  ]);

  // --- end of useEffects

  const debouncedSetSearchText = useDebouncedCallback((text: string) => {
    run(eSearchTask(text));
  }, 700);

  //  mark field by field
  function job_mark_field(mdText: string) {
    if (size(searchStringRef.current) < SEARCH_MIN_CHARS_NUMBER) {
      return md.render(mdText);
    }
    const regexParts = markRegexParts(searchStringRef.current);
    return mark(md.render(mdText))(regexParts);
  }
  //
  const tsOnCellPrepared = useCallback((e: any) => {
    if (e.column.name === "buttons") return;
    if (e.rowType === "data") {
      e.cellElement.classList.add("tscell-narrative-1");
      e.cellElement.innerHTML = DOMPurify.sanitize(job_mark_field(e.text));
    }
  }, []);

  //to excel
  const tsOnExporting = (e: any) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(function () {
      // https://github.com/exceljs/exceljs#writing-xlsx
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${friendlyName?.(listOrigin) || "raport"}.xlsx` // xls when delphi // (!) //
        );
      });
    });
    e.cancel = true;
  };

  //export to pdf
  const exportGridToPdf = useCallback(() => {
    if (!openPdf) {
      return;
    }
    openPdf({
      filename: `${friendlyName?.(listOrigin) || "raport"}.pdf`,
      showReportHeader: false,
      layoutParam: +layoutID === 0 ? "p" : "l",
    });
  }, [layoutID]);

  // const loadState = () => {
  //   // return state;
  //   // void 0;
  // };
  // const saveState = (e: any) => {
  //   local.state = e;
  //   // void 0;
  // };

  const tsOnContentReady = useCallback(() => {
    setTimeout(() => {
      document.getElementById("searchbox-random-id-971")?.focus();
    }, 1);
  }, []);

  const customShowColumnChooser = useCallback((e: any) => {
    if (!tsGridRef?.current) return;
    tsGridRef.current.instance.showColumnChooser();
    const columnChooserView = (
      tsGridRef.current.instance as unknown as typeof e.component
    ).getView("columnChooserView");
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
    }
    columnChooserView._popupContainer.option("position", {
      of: tsGridRef.current.instance.element(),
      my: "right top",
      at: "right top",
      offset: "-20 85",
    });
  }, []);

  const initialize = useCallback((e: any) => {
    if (!tsGridRef) return;
    tsGridRef.current = e;
  }, []);

  const showDupsOnly = useCallback(
    (e: any) => {
      run(eDuplicateStateTask(dupStates[e.itemIndex]));
    },
    [dupStateState]
  );

  /* -------------------------------------------------------------------------- */

  const anafQueryCui = useCallback(async (e: any) => {
    if (!tsGridRef?.current) return;
    const dataGrid = tsGridRef.current.instance;
    const myCui = dataGrid.cellValue(rowIndexRef.current, "CUI");
    if (!myCui) {
      return;
    }
    if (isNaN(+myCui)) {
      dispatch(
        appNotify({ message: "CUI-ul trebuie sa fie numeric", type: "error" })
      );
      return;
    }
    const qParams = new URLSearchParams({
      CUI: myCui,
      WDI: dataGrid.cellValue(rowIndexRef.current, "WDI"),
      DENUMIRE: dataGrid.cellValue(rowIndexRef.current, "DENUMIRE"),
    });
    const a = await fetch(`${service}/anaf/${myCui}?${qParams}`);
    if (a.status !== 200) {
      const b = await a.text();
      dispatch(appNotify({ message: b, type: "error" }));
      return;
    }
    const b = await a.json();
    const c = b?.found[0];
    const {
      denumire: AQ_DENUMIRE,
      nrRegCom: AQ_RECOM,
      adresa: AQ_ADRESA_FULL,
      telefon: AQ_TELEFON,
      sdenumire_Localitate: AQ_LOCALITATE,
      sdenumire_Judet: AQ_JUDET,
      cod_CAEN: AQ_CAEN,
    } = c;
    dataGrid.cellValue(rowIndexRef.current, "ANAF_QUERY", JSON.stringify(c));
    const levdenumire = levenshtein(
      (dataGrid.cellValue(rowIndexRef.current, "DENUMIRE") || "").toUpperCase(),
      (AQ_DENUMIRE || "").toUpperCase()
    );
    dataGrid.cellValue(
      rowIndexRef.current,
      "AQ_LEVENSTEIN_SCORE",
      (100 - levdenumire).toFixed(2)
    );
    dataGrid.cellValue(rowIndexRef.current, "AQ_DENUMIRE", AQ_DENUMIRE);
    dataGrid.cellValue(rowIndexRef.current, "AQ_RECOM", AQ_RECOM);
    dataGrid.cellValue(rowIndexRef.current, "AQ_ADRESA_FULL", AQ_ADRESA_FULL);
    dataGrid.cellValue(rowIndexRef.current, "AQ_TELEFON", AQ_TELEFON);
    dataGrid.cellValue(rowIndexRef.current, "AQ_LOCALITATE", AQ_LOCALITATE);
    dataGrid.cellValue(rowIndexRef.current, "AQ_JUDET", AQ_JUDET);
    dataGrid.cellValue(rowIndexRef.current, "AQ_CAEN", AQ_CAEN);
  }, []);

  const notesEditorOptions = {
    height: 140,
    maxLength: 8000,
  };

  const onEditingStart = (e: any) => {
    rowIndexRef.current = e.component.getRowIndexByKey(e.key);
  };

  const onEditorPreparing = (e: any) => {
    e.editorOptions.disabled =
      e.parentType == "dataRow" &&
      e.dataField == "ORGANIZATION_TYPE" &&
      !e.row.isNewRow;
  };

  const onEditorPrepared = (e: any) => {
    if (e.parentType == "dataRow" && e.dataField == "ANAF_QUERY") {
      if (e.row.isNewRow) {
        e.editorElement.style.display = "none";
      }
    }
  };

  const denumireOptions = {
    buttons: [
      {
        name: "google",
        location: "after",
        options: {
          icon: "info",
          onClick: async (e: any) => {
            //open google search in new tab
            if (!tsGridRef?.current) return;
            const dataGrid = tsGridRef.current.instance;
            const myDenumire = dataGrid.cellValue(
              rowIndexRef.current,
              "DENUMIRE"
            );
            if (!myDenumire) {
              return;
            }
            let myCui = dataGrid.cellValue(rowIndexRef.current, "CUI");
            myCui = myCui ? `+${myCui}` : "";
            let myLocalitate = dataGrid.cellValue(
              rowIndexRef.current,
              "LOCALITATE"
            );
            myLocalitate = myLocalitate ? `+${myLocalitate}` : "";
            const qParams = new URLSearchParams({
              q: `${myDenumire}${myCui}${myLocalitate}`,
            });
            window.open(`https://www.google.com/search?${qParams}`, "_blank");
          },
        },
      },
    ],
  };
  const organizationTypeOptions = {
    dataSource: theFrmOrgTypes,
    valueExpr: "ORG_TYPE_NAME",
    displayExpr: "ORG_TYPE_NAME",
    elementAttr: {
      style: "background-color: #f5f5f5;",
    },
  };

  const anafButtonOptions = {
    text: "ANAF",
    type: "default",
    icon: "download",
    onClick: anafQueryCui,
  };

  const scoreEditorOptions = {
    elementAttr: {
      class: "dx-field-item-content-score",
    },
  };

  const aqdenumireOpts = {
    buttons: [
      {
        name: "anaf",
        location: "after",
        options: {
          icon: "upload",
          onClick: async (e: any) => {
            if (!tsGridRef?.current) return;
            const dataGrid = tsGridRef.current.instance;
            const crtDen = dataGrid.cellValue(
              rowIndexRef.current,
              "AQ_DENUMIRE"
            );
            if (!crtDen) return;
            dataGrid.cellValue(rowIndexRef.current, "DENUMIRE", crtDen);
          },
        },
      },
    ],
  };

  const aqcaenOpts = {
    buttons: [
      {
        name: "anaf",
        location: "after",
        options: {
          icon: "upload",
          onClick: async (e: any) => {
            if (!tsGridRef?.current) return;
            const dataGrid = tsGridRef.current.instance;
            const crtDen = dataGrid.cellValue(rowIndexRef.current, "AQ_CAEN");
            if (!crtDen) return;
            dataGrid.cellValue(rowIndexRef.current, "COD_CAEN", crtDen);
          },
        },
      },
    ],
  };

  /* -------------------------------------------------------------------------- */
  // on page orientation change
  const changeGridLayout = (e: any) => {
    if (!tsGridRef?.current) return;
    const currentState = structuredClone(tsGridRef.current.instance.state());
    // current state with the next layoutID:
    const newState = Object.freeze(
      Object.assign({}, currentState, {
        layoutID: e.itemIndex,
      })
    );
    run(eTApplyGridState(newState as IListState));
  };
  //reset
  const resetToInitialState = () => {
    run(eTApplyGridState("initial"));
  };
  //search
  const tsSrchBoxValueChanged = (e: any) => {
    debouncedSetSearchText(e.value);
  };
  /* -------------------------------------------------------------------------- */
  return (
    <DataGrid
      elementAttr={{
        "data-layoutID": layoutID,
      }}
      id={`anyGrid-${listOrigin}`}
      ref={initialize}
      className={memoClassName}
      style={{ maxWidth: GRID_LAYOUTS[`${layoutID}`] }}
      height={fullHeight || "auto"}
      dataSource={anyPlainDataSource}
      showBorders={true}
      showRowLines={true}
      showColumnLines={true}
      columnAutoWidth={false}
      allowColumnReordering={true}
      allowColumnResizing={true}
      autoNavigateToFocusedRow={true}
      focusedRowEnabled={false}
      filterSyncEnabled={true}
      repaintChangesOnly={true}
      renderAsync={undefined}
      wordWrapEnabled={true}
      noDataText={""}
      onExporting={tsOnExporting}
      onContentReady={tsOnContentReady}
      onCellPrepared={tsOnCellPrepared} // jurnal gold format //
      onEditingStart={onEditingStart}
      onEditorPreparing={onEditorPreparing}
      onEditorPrepared={onEditorPrepared}
    >
      <ColumnChooser enabled={true} title={"Alege coloanele"} width={280} />
      <ColumnFixing enabled={false} />
      <Editing
        mode="popup"
        allowUpdating={true}
        allowAdding={false}
        allowDeleting={true}
        useIcons={true}
        texts={{
          confirmDeleteMessage:
            "Sunteti sigur ca doriti sa stergeti aceasta inregistrare?",
          deleteRow: "Sterge",
          editRow: "Modifica",
          updateRow: "Salveaza",
          addRow: "Adauga",
        }}
      >
        <Popup
          title="Date membru UGIR"
          showTitle={true}
          width={825}
          height={765}
        />
        <Form colCount={1}>
          <FormItem itemType="group" colCount={2}>
            <FormItem
              dataField="DENUMIRE"
              colSpan={2}
              editorOptions={denumireOptions}
            ></FormItem>
            <FormItem dataField="CUI" />
            <FormItem dataField="SALARIATI" />
            <FormItem dataField="JUDET" />
            <FormItem dataField="LOCALITATE" />
            <FormItem dataField="LOCALITATE2" />
            <FormItem dataField="COD_CAEN" />
            <FormItem dataField="ALTE_INFO" colSpan={2} />
            <FormItem dataField="BRANCH_NAME" disabled={true} colSpan={2} />
            <FormItem
              dataField="ORGANIZATION_TYPE"
              editorType="dxSelectBox"
              editorOptions={organizationTypeOptions}
              colSpan={2}
            />
            <FormItem dataField="SESSION_NAME" disabled={true} />
            <FormItem dataField="WDI" disabled={true} label={{ text: "ID" }} />
            <FormItem dataField="BID" visible={false} />
            <FormItem dataField="RSID" visible={false} />
            <FormItem dataField="SID" visible={false} />
            <FormItem dataField="INSERTED" disabled={true} />
            <FormItem dataField="UPDATED" disabled={true} />
            <FormItem dataField="INSERT_UID" visible={false} />
            <FormItem dataField="UPDATED_UID" visible={false} />
            <FormItem dataField="IMPORT_KEY" visible={false} />
            <FormItem dataField="IMPORT_UID" visible={false} />
            <FormItem dataField="RESOURCE_NAME" colSpan={2} disabled={true} />
            <FormGroupItem caption="Interogare Anaf" colSpan={2}>
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                <Tab title="Rezultat">
                  <FormItem
                    dataField="AQ_LEVENSTEIN_SCORE"
                    editorType="dxTextBox"
                    editorOptions={scoreEditorOptions}
                  ></FormItem>
                </Tab>
                <Tab title="Campuri semnificative" colCount={2}>
                  <SimpleItem
                    dataField="AQ_DENUMIRE"
                    editorOptions={aqdenumireOpts}
                  />
                  <SimpleItem dataField="AQ_RECOM" />
                  <SimpleItem dataField="AQ_ADRESA_FULL" />
                  <SimpleItem dataField="AQ_TELEFON" />
                  <SimpleItem dataField="AQ_LOCALITATE" />
                  <SimpleItem dataField="AQ_JUDET" />
                  <SimpleItem dataField="AQ_CAEN" editorOptions={aqcaenOpts} />
                </Tab>
                <Tab title="Date brute">
                  <FormItem
                    dataField="ANAF_QUERY"
                    editorType="dxTextArea"
                    editorOptions={notesEditorOptions}
                  >
                    <Label render={LabelAnaf} showColon={false} />
                  </FormItem>
                </Tab>
              </TabbedItem>
              <ButtonItem
                horizontalAlignment="left"
                buttonOptions={anafButtonOptions}
              />
            </FormGroupItem>
          </FormItem>
        </Form>
      </Editing>
      <Export enabled={true} texts={{ exportAll: "export in excel" }} />
      <FilterPanel visible={true} />
      <FilterRow visible={false} />
      <Grouping autoExpandAll={true} allowCollapsing={false} />
      <GroupPanel
        visible={true}
        emptyPanelText={"Panou de grupare (coloane)"}
      />
      <HeaderFilter visible={true} searchMode={"contains"} allowSearch={true} />
      <LoadPanel enabled={false} shading={false} shadingColor="#fffefe" />
      <Paging enabled={true} pageSize={100} />
      <Scrolling
        mode={"virtual"}
        preloadEnabled={false}
        renderAsync={undefined}
      />
      <Selection mode="single" />
      <Sorting mode={"multiple"} />
      <StateStoring
        enabled={false}
        type="custom"
        // customLoad={void 0}
        // customSave={void 0}
      />
      <Toolbar>
        <Item location="before" name="groupPanel" />
        <Item {...sessionSelectOpts()} />
        <Item
          {...showDupsGroupOpts({ ico: dupStateState, showDups: showDupsOnly })}
        />
        <Item {...layoutButtonGroupOpts({ layoutID, changeGridLayout })} />
        <Item
          {...saveStateButtonOpts({
            listOrigin,
            anyListGridRef: tsGridRef,
            resetToInitialState,
          })}
        />
        <Item {...exportToPdfOpts({ exportGridToPdf })} />
        <Item location="center" name="exportButton" />
        <Item {...columnChooserButtonOpts({ customShowColumnChooser })} />
        <Item name={"addRowButton"} location={"after"} />
        <Item
          {...searchBoxOpts({
            tsSearchBoxRef,
            tsSrchBoxValue,
            tsSrchBoxValueChanged,
          })}
        />
      </Toolbar>
      {loadedColumnsRef.current.map((x, i) => {
        return (
          <Column
            key={`${listOrigin}-${x}-${i}`}
            dataField={x}
            {...fieldDefinitions?.[x]}
          ></Column>
        );
      })}
      <Column type={"buttons"} width={100}>
        <Button name="edit" text={"editare"} />
        <Button name="delete" text={"stergere"} />
      </Column>
      <Summary calculateCustomSummary={calculateWithDistinct}>
        <GroupItem {...groupCustomItem("CUI")} />
        <GroupItem {...groupSum("SALARIATI", "")} />
        <TotalItem {...groupCustomTotals("CUI", "")} />
        <TotalItem {...groupSum("SALARIATI", "")} />
      </Summary>
      <SortByGroupSummaryInfo summaryItem="count" />
    </DataGrid>
  );
};
export default memo(AnyGrid);

//npx eslint "%cd%"\src\components\lists\lxr_anyGrid.tsx --fix
//npx eslint ./src/components/lists/lxr_anyGrid.tsx --fix
