import React from "react";
import { useAppDispatch } from "@app/state";
import { Switch, Case, CaseElse } from "@app/contexts";
import Button from "devextreme-react/button";
import { confirm } from "devextreme/ui/dialog";
import * as appConstants from "@app/state/constants";
import { launchAnafCrosscheck } from "@app/state/units";
import { AnyGrid } from "@app/components";
import { TUIPageName } from "@app/types";

const WorkDataMain = ({ currentPage }: { currentPage: TUIPageName }) => {
  const dispatch = useAppDispatch();
  return (
    <div>
      <Switch value={currentPage}>
        <Case when={"workdata/any"} mounted>
          <AnyGrid listOrigin={appConstants.CONST_LSTORIGIN_WORKDATA} />
        </Case>
        <Case when={"workdata/sq"} mounted>
          {/* <SagaGrid listOrigin={appConstants.CONST_LSTORIGIN_WORKDATA} /> */}
          <Button
            text={"exec. serviciu cross-check"}
            icon={"preferences"}
            onClick={async () => {
              console.log("exec. serviciu cross-check");
              const go = await confirm(
                `<p>Interogarea ANAF pentru toate datele, poate fi de lunga durata.</br> Rezutatul se va scrie in baza de date si va fi disponibil pe refresh, pe masura ce se proceseaza.<br/> Facem?</p>`,
                "Confirmare"
              );
              if (!go) return;
              dispatch(launchAnafCrosscheck());
            }}
          ></Button>
        </Case>
      </Switch>
    </div>
  );
};

export default WorkDataMain;
