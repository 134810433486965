import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
export const isObject = (obj?: any) => typeof obj === "object" && obj !== null;

/**
 * Encode a string of text as base64
 *
 * @param data The string of text.
 * @returns The base64 encoded string.
 */
export function encodeBase64(data: string) {
  if (typeof btoa === "function") {
    return btoa(data);
  } else if (typeof Buffer === "function") {
    return Buffer.from(data, "utf-8").toString("base64");
  } else {
    throw new Error("Failed to determine the platform specific encoder");
  }
}

/**
 * Decode a string of base64 as text
 *
 * @param data The string of base64 encoded text
 * @returns The decoded text.
 */
export function decodeBase64(data: string) {
  if (typeof atob === "function") {
    return atob(data);
  } else if (typeof Buffer === "function") {
    return Buffer.from(data, "base64").toString("utf-8");
  } else {
    throw new Error("Failed to determine the platform specific decoder");
  }
}

export function isValidDate(date: any) {
  return (
    date &&
    Object.prototype.toString.call(date) === "[object Date]" &&
    !Number.isNaN(date)
  );
}

export function deserializeDate(date: Date | string | null): string {
  const d = isValidDate(date)
    ? (date as Date).toISOString()
    : (date as string | null);
  return d || "";
}

export function subtractYears(numOfYears = 1, date = new Date()) {
  const dateCopy = new Date(date.getTime());
  dateCopy.setFullYear(dateCopy.getFullYear() - numOfYears);
  return dateCopy;
}
export function subtractMonths(numOfMonth = 1, date = new Date()) {
  const dateCopy = new Date(date.getTime());
  dateCopy.setMonth(dateCopy.getMonth() - numOfMonth);
  return dateCopy;
}

export function isIsoDate(str: string): Date | false {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
    return false;
  }
  try {
    const d = new Date(str);
    return d instanceof Date && !isNaN(+d) && d.toISOString() === str
      ? d
      : false;
  } catch (e) {
    console.log("e", e);
    return false;
  }
}
function parseISOSafe(str: string): Date | null {
  try {
    const d = parseISO(str);
    if (d instanceof Date && !isNaN(+d)) {
      return d;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
export const dateValue = (x: any, dateFormat: string) => {
  // basically, if we provide a datefomat, we know where the date comes from
  // A. from delphi - json by lkjson - then the date is in system format
  // B. from the server -json through synDb - then the date is in iso 8601 format;
  if (dateFormat) {
    const d = new Date(parse(x as string, dateFormat, new Date()));
    if (isValidDate(d)) {
      return d;
    }
  }
  // if not provided or errors there, we try to parse the date in various formats:
  const d = isIsoDate(x);
  if (d) {
    if (isValidDate(d)) {
      return d;
    }
  }

  // the standard format is iso8601 (or partial) (if not UNIX timestamp)
  // YYYY-MM-DDTHH:mm:ss.sssZ
  const u = parseISOSafe(x);
  if (u) {
    return u;
  }

  //we have no window in the worker!
  try {
    if (dateFormat) {
      return new Date(parse(x as string, dateFormat, new Date()));
    }
    //we have to parse the date comming from the server but we don't have a date format
    //we will try to parse it with the default date format
    //we give up:
    return null;
  } catch (error) {
    console.log("error #328:", error, "for:", x);
    return null;
  }
};
export const dateValueOrNull = (x: any) => {
  if (isIsoDate(x)) {
    return new Date(x);
  }
  try {
    if (isValidDate(x)) {
      return x;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
// new Intl.DateTimeFormat('en-US').format;

export const ensureArray = (ar: unknown) =>
  Array.isArray(ar) ? ar : [ar].filter((f) => f !== undefined);

export const toMinutes = (x: string) => {
  try {
    const [h, m] = x.split(":");
    return parseInt(h, 10) * 60 + parseInt(m, 10);
  } catch (e) {
    console.log(x, "<< e >>", e);
    return 0;
  }
};

export const toHoursMinutes = (mins: number, long = false) => {
  const sig = mins < 0 ? "-" : "";
  mins = Math.abs(mins);
  const h = Math.floor(mins / 60);
  const m = mins % 60;
  const hh = h < 10 ? `0${h}` : h;
  const mm = m < 10 ? `0${m}` : m;
  // if(long && h === 1) return `o oră ${mm} min.`;
  if (long) {
    return `${hh} ore ${mm} min.`;
  }
  return `${sig}${hh}:${mm}`;
};

export const isTrusted = (e: any) =>
  Boolean(
    e?.event &&
      typeof e.event === "object" &&
      e.event !== null &&
      Reflect.get(e.event, "isTrusted")
  );

//https://www.trysmudford.com/blog/linear-interpolation-functions/
export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
export const invlerp = (x: number, y: number, a: number) =>
  clamp((a - x) / (y - x));
export const clamp = (a: number, min = 0, max = 1) =>
  Math.min(max, Math.max(min, a));
export const range_lerp = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  a: number
) => lerp(x2, y2, invlerp(x1, y1, a));

export function linear_scale(
  value_in_old_scale: number,
  old_scale_minimum: number,
  old_scale_maximum: number,
  new_scale_minimum: number,
  new_scale_maximum: number
): number {
  const old_scale_range = old_scale_maximum - old_scale_minimum;
  // the value problem:: if [-480, -480] => [100, 200] => NaN | we need to return 100
  if (old_scale_range === 0) {
    return new_scale_minimum;
  }

  const new_scale_range = new_scale_maximum - new_scale_minimum;
  return (
    ((value_in_old_scale - old_scale_minimum) * new_scale_range) /
      old_scale_range +
    new_scale_minimum
  );
}

const deepSortObject = (opts?: any): any => {
  if (!isObject(opts)) {
    return opts;
  }
  return Object.keys(opts)
    .sort()
    .reduce((res: any, key: any) => {
      res[`${key}`] = opts[key];
      if (opts[key] && isObject(opts[key])) {
        res[`${key}`] = deepSortObject(opts[key]);
      }
      return res;
    }, {});
};

// export const createActionKey = (name: string, options?: any) => {
//   const enc =
//     typeof options !== 'undefined'
//       ? encodeBase64(JSON.stringify(deepSortObject(options)))
//       : '';
//   const encKey = enc ? `|${enc}` : '';
//   const key = `${name}${encKey}`;
//   return key;
// };

/* -------------------------------------------------------------------------- */
// export const noCase = (s: string): string => {
//   return s && typeof s === 'string'
//     ? s.toLowerCase().replaceAll(' ', '').replaceAll('-', '')
//     : undefined;
// };

//guess server date format from the date string
// `SELECT '5 OCT 2001' AS MARKER_AS_TEXT,  DATEADD( 4 DAY TO DATEADD(9 MONTH TO CAST('1/1/1' AS DATE)))AS  SERVER_DATE_VALUE FROM RDB$DATABASE;`;
