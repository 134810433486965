import React from "react";
import { useContext, useRef, createContext, useEffect } from "react";
import dxDataGrid from "devextreme/ui/data_grid";
import { pdfEngine, destroySession } from "../reports/pdf_engine";
import { CONST_LISTORIGIN_DICTIONARY } from "@app/state/constants";
import { TListOriginName, PdfContextType } from "../types";
/* -------------------------------------------------------------------------- */
//TODO: #42 estimate the size of the data and if is too big then notify the to export to excel...

const PdfContext = createContext<PdfContextType>({});

export function GatherPdfData(props: any) {
  useEffect(() => {
    return () => {
      destroySession();
    };
  }, []);

  const pdfData = useRef<dxDataGrid>();
  const tsGridRef = useRef<dxDataGrid>();
  const lstContactsRef = useRef<dxDataGrid>();
  const anyListGridRef = useRef<dxDataGrid>();
  const detailRef = useRef<dxDataGrid[]>([]);
  //other data..//
  async function openPdf(pdfRootObject: any) {
    const refs = Object.assign(
      {},
      { pdfData: pdfData.current },
      { tsGridRef: tsGridRef?.current?.instance || {} },
      { detailRef: detailRef },
      { lstContactsRef: lstContactsRef?.current?.instance || {} },
      { anyListGridRef: anyListGridRef?.current?.instance || {} }
    );
    await pdfEngine(pdfRootObject, refs);
    // TODO: #54 on success log to access log //
  }
  function friendlyName(listOrigin: TListOriginName) {
    switch (CONST_LISTORIGIN_DICTIONARY?.[listOrigin] !== undefined) {
      case true:
        return CONST_LISTORIGIN_DICTIONARY[listOrigin];
      default:
        return "Timesheet.pdf";
    }
  }
  //
  return (
    <PdfContext.Provider
      value={{
        pdfData,
        tsGridRef,
        lstContactsRef,
        anyListGridRef,
        detailRef,
        openPdf,
        friendlyName,
      }}
      {...props}
    />
  );
}

export const usePdfContext = () => useContext(PdfContext);
