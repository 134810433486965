import React from "react";
import { Button } from "devextreme-react/button";
import { useAuth0 } from "@auth0/auth0-react";
const NotAuth = () => {
  const { loginWithPopup } = useAuth0();
  return (
    <div className="not-authenticated">
      <h1>{"Trebuie să te autentifici"}</h1>
      <Button
        text={"Intra in cont"}
        stylingMode={"outlined"}
        onClick={() => {
          loginWithPopup({
            authorizationParams: {
              max_age: 1,
              prompt: "login",
              login_hint: localStorage.getItem("login_hint") || "",
            },
          });
        }}
      />
    </div>
  );
};

export default NotAuth;
