import { Remarkable } from "remarkable";
import { mention, mention_hsh } from "@app/service/mdPlugins";
import compose from "lodash/fp/compose";
import curry from "lodash/fp/curry";
import filter from "lodash/fp/filter";
import map from "lodash/fp/map";
import reduce from "lodash/fp/reduce";
import size from "lodash/fp/size";
import split from "lodash/fp/split";
import toLower from "lodash/fp/toLower";
import trim from "lodash/fp/trim";
import uniq from "lodash/fp/uniq";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import { dateValue, ensureArray } from "@app/service/helpers";
import createDOMPurify from "dompurify";
import proxy from "proxy-states";
import {
  CONST_LSTORIGIN_WORKDATA,
  CONST_LSTORIGIN_NOP,
} from "@app/state/constants";
import { IListRecord } from "@app/types";
/* ---------------------------- QUERY PARAMETERS ---------------------------- */

// export const listParams = proxy({
//   [CONST_LIST_SS_OF_MYWORK]: {
//     start: null,
//     end: null,
//   },
//   setWork: (state, start, end) => {
//     state[CONST_LIST_SS_OF_MYWORK].start = start;
//     state[CONST_LIST_SS_OF_MYWORK].end = end;
//   },
//   getWork: (state) => {
//     return state[CONST_LIST_SS_OF_MYWORK];
//   },
// });

/* ------------------------ FIELD DEFINITION HELPERS ------------------------ */

const normalizeDate = (df: any) => {
  // this should do the trick:
  if (isValid(new Date(df))) {
    return new Date(df);
  }

  return isValid(new Date(df)) ? new Date(df) : new Date(0);
};

function normalizeDisplayValue(x: any) {
  return x ? normalizeDate(x) : "";
}

const calcDateCellValue = curry((field: any, dt: any) => {
  return normalizeDate(dt?.[field]);
});
const calcDateCellValueDisplay = curry((field: any, dt: any) => {
  return normalizeDisplayValue(dt?.[field]);
});

const customizeDateFieldHeaderFilterData = (options: any) => {
  options.dataSource.postProcess = function (results: any[]) {
    return compose(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (x: any[]) => {
        const u = ensureArray(x);
        u.unshift({ key: 1970, text: "(Gol)", value: 1970 });
        return u;
      },
      filter((x: any) => x["key"] !== 1970)
    )(results);
  };
};
// we play it with css adding a classname to parent
// note: the code works  but there is a flickering effect in the grid
// the cause is form the grid itself, not from the code below
// anyway is more logical to hide the column or show it, not to truncate the text...
// e.g:  [##findme012323##]

/* ---------------------------- FIELD DEFINITIONS --------------------------- */
const invisibleID = {
  dataType: "number",
  visible: false,
  showInColumnChooser: false,
};

/* 

export interface IListRecord {
  ALTE_INFO: string | null;
  BID: number;
  BRANCH_NAME: string;
  COD_CAEN: string | null;
  CUI: string;
  DENUMIRE: string;
  IMPORT_KEY: string;
  IMPORT_UID: number;
  INSERTED: string;
  INSERT_UID: string | null;
  JUDET: string;
  LOCALITATE: string | null;
  LOCALITATE2: string | null;
  ORGANIZATION_TYPE: string;
  RESOURCE_NAME: string;
  RSID: number;
  SALARIATI: number;
  SESSION_NAME: string;
  SID: number;
  UPDATED: string;
  UPDATED_UID: string | null;
  WDI: number;
  ANAF_QUERY: string;
}

*/
type TFieldDef = {
  [key in keyof IListRecord]: any;
};

export const fieldDefs = {
  [`_KEYOF_TABLE_${CONST_LSTORIGIN_WORKDATA}`]: ["WDI"],
  WDI: { ...invisibleID, showInColumnChooser: true },
  SID: { ...invisibleID },
  BID: { ...invisibleID },
  RSID: { ...invisibleID },
  CUI: {
    dataType: "string",
    caption: "CUI",
    visibleIndex: 0,
    width: 110,
  },
  DENUMIRE: {
    dataType: "string",
    caption: "Denumire",
    visibleIndex: 1,
  },
  JUDET: {
    dataType: "string",
    caption: "Judet",
    visibleIndex: 2,
    width: 150,
  },
  LOCALITATE: {
    dataType: "string",
    caption: "Localitate",
    visibleIndex: 3,
    width: 150,
  },
  LOCALITATE2: {
    dataType: "string",
    caption: "Localitate (2)",
    visible: false,
  },
  SALARIATI: {
    dataType: "number",
    caption: "Numar salariati",
    visibleIndex: 4,
    width: 70,
  },
  COD_CAEN: {
    dataType: "string",
    caption: "Cod CAEN",
    visibleIndex: 5,
    width: 140,
  },
  ALTE_INFO: {
    dataType: "string",
    caption: "Alte informatii",
    visible: false,
  },
  IMPORT_KEY: { ...invisibleID, dataType: "string" },
  IMPORT_UID: { ...invisibleID },
  INSERTED: {
    dataType: "date",
    caption: "Data inserarii",
    calculateCellValue: calcDateCellValue("INSERTED"),
    calculateDisplayValue: calcDateCellValueDisplay("INSERTED"),
    headerFilterDataSource: customizeDateFieldHeaderFilterData,
    visible: false,
  },
  INSERT_UID: { ...invisibleID },
  UPDATED: {
    dataType: "date",
    caption: "Data actualizarii",
    calculateCellValue: calcDateCellValue("UPDATED"),
    calculateDisplayValue: calcDateCellValueDisplay("UPDATED"),
    headerFilterDataSource: customizeDateFieldHeaderFilterData,
    visible: false,
  },
  UPDATED_UID: { ...invisibleID },
  ORGANIZATION_TYPE: {
    dataType: "string",
    caption: "Tipul organizatiei",
    visible: false,
  },

  BRANCH_NAME: {
    dataType: "string",
    caption: "Numele organizatiei",
    visible: false,
  },
  RESOURCE_NAME: {
    dataType: "string",
    caption: "Sursa datelor",
    visible: false,
  },
  SESSION_NAME: {
    dataType: "string",
    caption: "Sesiunea de raportare",
    visible: false,
  },
  ANAF_QUERY: {
    dataType: "string",
    caption: "*",
    visible: false,
    showInColumnChooser: false,
  },
  AQ_DENUMIRE: {
    dataType: "string",
    caption: "Denumire",
    visible: false,
    showInColumnChooser: false,
  },
  AQ_RECOM: {
    dataType: "string",
    caption: "Reg Com",
    visible: false,
    showInColumnChooser: false,
  },
  AQ_ADRESA_FULL: {
    dataType: "string",
    caption: "Adresa",
    visible: false,
    showInColumnChooser: false,
  },
  AQ_TELEFON: {
    dataType: "string",
    caption: "Telefon",
    visible: false,
    showInColumnChooser: false,
  },
  AQ_LOCALITATE: {
    dataType: "string",
    caption: "Localitate",
    visible: false,
    showInColumnChooser: false,
  },
  AQ_JUDET: {
    dataType: "string",
    caption: "Judet",
    visible: false,
    showInColumnChooser: false,
  },
  AQ_CAEN: {
    dataType: "string",
    caption: "Cod CAEN",
    visible: false,
    showInColumnChooser: false,
  },
  AQ_LEVENSTEIN_SCORE: {
    caption: "Scor Potrivire",
    dataType: "percent",
    visible: false,
    showInColumnChooser: false,
  },
  AQN_RESULT: {
    caption: "Status ANAF",
    dataType: "string",
    visible: false,
    showInColumnChooser: true,
    width: 70,
  },
  AQN_LEVENSTIEIN_AS_MATCH: {
    caption: "Concordanta ANAF",
    dataType: "number",
    format: {
      type: "percent",
      precision: 0,
    },
    visible: false,
    showInColumnChooser: true,
    width: 70,
  },
} as TFieldDef & { [key: string]: any[] };

/* ---------------------------- COMMON FUNCTIONS ---------------------------- */

export const DOMPurify = createDOMPurify(window);

export const SEARCH_MIN_CHARS_NUMBER = 3;

export const md = new Remarkable("full", {
  html: true,
  xhtmlOut: true,
  breaks: false,
  typographer: true,
});
md.inline.ruler.enable(["ins", "mark"]);
md.inline.ruler.disable(["text", "links", "htmltag", "autolink", "entity"]);
md.block.ruler.disable(["table", "footnote"]);
//
md.use(mention).use(mention_hsh);
//
const rgx = "?![^<>]*>";
//
const _parts = (x: string) =>
  compose(
    uniq,
    map((x) => trim(x as string)),
    filter((x: string) => size(x) > 2),
    // initially we just used:  split(' '),
    //map by words but extract text between quotes
    (x) =>
      (x.match(/".*?"|[^\s]+/g) || []).map((s) => s.replace(/^"(.*)"$/, "$1")),
    toLower,
    trim
  )(x);
//
export const parts = curry(_parts);
//
const _regexParts = (partsArray: string[]) =>
  map(
    (x) =>
      new RegExp(
        `(${trim(x.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"))})(${rgx})`,
        "gi"
      ),
    partsArray
  );
//
export const regexParts = curry(_regexParts);
//
const _mark = (x: any, y: any[]) =>
  reduce(
    (html2, regex) =>
      compose(
        reduce(
          (html, part) =>
            regex.test(part) ? `${html}<mark>${part}</mark>` : `${html}${part}`,
          ""
        ),
        split(regex)
      )(html2),
    x,
    y //regexParts
  );
//
export const mark = curry(_mark);
//
export const markRegexParts = (value: any) => {
  const parts = compose(
    uniq,
    map((x) => trim(x as string)),
    filter((x) => size(x) > 2),
    split(" "),
    toLower,
    trim
  )(value);

  const rgx = "?![^<>]*>";

  return map((x) => new RegExp(`(${trim(x)})(${rgx})`, "gi"), parts);
};
