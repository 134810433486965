import { all, spawn } from 'saga-query';
import { resetStoreSaga } from '@app/state/resetStore';

function* start() {
  const now = new Date();
  const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
  const formattedTime = `${now.getHours().toString().padStart(2, '0')}:${now
    .getMinutes()
    .toString()
    .padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;

  yield console.log(
    `Softwiz Creation \u00A9 ${new Date().getFullYear()}| JurnalGold | run. ${formattedDate} ${formattedTime}`
  );
  //(!)// do not change, see prebuild script //(!)//
  yield console.log('UGIR build id: 83/2023-06-06 01:04:17')
}
export default function* rootSaga() {
  yield* all([spawn(start), spawn(resetStoreSaga)]);
}
