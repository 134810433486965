import {
  pdfState,
  transformUnits,
  width2Page,
  margins,
  justPx2Page,
} from "./pdf_state";
import { service } from "@app/service";

export async function addLogo(pdf: any, pdfKey: string) {
  // starting point coordinates: you can get those as params or however you want
  const x = 0; // the x coordinate of the upper-left corner of the image
  const y = 0; // the y coordinate of the upper-left corner of the image
  // we could pass parameters from outside but we don't need it now, for demo purposes
  const { apiKey, width, height } = pdfState.mainParams(pdfKey).logo;
  // the aim here is to set the desired size of the loge. The procedure will calculate the correct proportions and print the logo
  // optimal values for the future image:
  const targetedWidth =
    width2Page(width, "33.33%") - 1.5 * transformUnits(margins.left); // you can change the value of the displayed image
  const targetedHeight = justPx2Page(height);
  // the image:
  const img = new Image();

  // get the image from the server (delphi asset store - TForm):
  // you can get any resource you need in a similar manner (as text or as json)
  const request = await fetch(`${service}/assets/${apiKey}`);
  const base64img = await request.text();

  img.src = `data:image/jpg;base64,${base64img}`;
  img.alt = "Logo";
  // no width or height -- !IMPORTANT;

  const imgDone = await img.decode().catch((e) => e);

  if (imgDone instanceof Error) {
    // wasted time here instead of having try/catch
    console.log(imgDone.message, " #934 ");
    return pdf;
  }
  // adjust image keeping aspect
  const ratioOrig = img.height / img.width;
  let heightCurrent = targetedHeight;
  let widthCurrent = heightCurrent / ratioOrig;
  // if still wider, then adjust again:
  if (widthCurrent > targetedWidth) {
    heightCurrent = ratioOrig * targetedWidth;
    widthCurrent = targetedWidth;
  }
  await pdf.addImage(
    img,
    "JPEG",
    x,
    y,
    widthCurrent,
    heightCurrent,
    `ALIAS:${apiKey}`,
    "SLOW",
    0
  );
  pdfState.setY({ pdfKey: pdfKey, section: "logo", y: y + heightCurrent });
  return pdf;
}
