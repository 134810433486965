export const GRID_LAYOUTS = {
  "0": "29.5cm" as React.CSSProperties["width"],
  "1": `${29.5 * 1.61803}cm` as React.CSSProperties["width"],
  "2": "100%" as React.CSSProperties["width"],
}; //
export const CONST_RED_ALERT = "%REDALERTALERT%";
export const CONST_LSTORIGIN_WORKDATA = "LIST_WORKDATA_ORIGIN";
export const CONST_LSTORIGIN_NOP = "LIST_NOP_ORIGIN";
export const CONST_LISTORIGIN_DICTIONARY = {
  [CONST_LSTORIGIN_WORKDATA]: "Registru Membri",
  [CONST_LSTORIGIN_NOP]: "Nop",
} as const;
