import React, { useCallback } from "react";
import Button from "devextreme-react/button";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../state";

import { uiiApii, logout as logoutApi } from "../../state/units";

import { appNotify } from "@app/state/units";
import { uiCurrentPageSelector } from "../../state/units/ui";
import "./drawer.scss";

const DrawerInside = ({ toggleDrawer }: { toggleDrawer: () => void }) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector(uiCurrentPageSelector);
  const { logout } = useAuth0();

  const openList = useCallback(() => {
    dispatch(uiiApii({ currentPage: "workdata/any" }));
  }, []);
  const openListSq = useCallback(() => {
    dispatch(uiiApii({ currentPage: "workdata/sq" }));
  }, []);
  const openResurse = useCallback(() => {
    dispatch(uiiApii({ currentPage: "resources" }));
  }, []);

  const doNop = useCallback(() => {
    dispatch(uiiApii({ currentPage: "nop" }));
  }, []);

  return (
    <div id="drawer-main">
      <div className="drawer-item">
        <Button
          width={64}
          height={64}
          stylingMode={"text"}
          text={""}
          icon={"menu"}
          onClick={toggleDrawer}
          visible={false}
        />
      </div>
      <div className="drawer-item">
        <Button
          className={`xicoTimeSheet ${
            currentPage === "workdata/any" ? "dx-item-selected" : ""
          }`}
          width={64}
          height={64}
          stylingMode={"text"}
          text={"Lista crt."}
          onClick={openList}
          icon={"rowproperties"}
        />
      </div>
      <div className="drawer-item">
        <Button
          className={`xicoTimeSheet ${
            currentPage === "workdata/sq" ? "dx-item-selected" : ""
          }`}
          width={64}
          height={32}
          stylingMode={"outlined"}
          onClick={openListSq}
          icon={"more"}
          disabled={false}
        />
      </div>
      <div className="drawer-item">
        <Button
          elementAttr={{ id: "btnTimeSheet" }}
          className={`xicoBubble ${
            currentPage === "resources" ? "dx-item-selected" : ""
          }`}
          width={64}
          height={64}
          stylingMode={"text"}
          text={"Resurse"}
          onClick={openResurse}
          icon={"folder"}
          visible={false}
        />
      </div>
      <div className="drawer-item">
        <Button
          className="xicoGroup"
          width={64}
          height={64}
          stylingMode={"text"}
          text={"Setari"}
          icon={"preferences"}
          visible={false}
        />
      </div>
      <div className="drawer-item">
        <Button
          width={64}
          height={64}
          stylingMode={"text"}
          text={"Out"}
          icon={"close"}
          onClick={() => {
            dispatch(logoutApi());
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
            dispatch(
              appNotify({
                message: "Iesire din aplicatie",
                type: "info",
                duration: 2000,
                position: "bottom",
              })
            );
          }}
        />
      </div>
    </div>
  );
};
export default DrawerInside;
