//external libs
import { createTable, createAssign } from "robodux";
import {
  leading,
  put,
  spawn,
  select,
  // setLoaderError,
  // setLoaderStart,
  // setLoaderSuccess,
} from "saga-query";
import { createSelector } from "reselect";
import { compose, map, reduce } from "lodash/fp";
//our libs
import * as appConstants from "@app/state/constants";
import { api, thunks } from "@app/state/apis/api";
import { appNotify } from "./notify";
//types
import type { ApiCtx } from "saga-query";
import type { RootState } from "../rootState";
import type {
  IDBWorkdata,
  IListState,
  IDBListLayout,
  TLayoutId,
  IListRecord,
} from "@app/types";

const REPO_NAME = "list";

export const workDataRepo = createTable<IDBWorkdata>({
  name: REPO_NAME,
});

export const workDataLayoutRepo = createAssign<IListState>({
  name: `${REPO_NAME}-layout`,
  initialState: {
    layoutID: 0,
  },
});

export const loadWorkData = api.get(
  "/workdata",
  { saga: leading },
  function* loadfulldb(ctx: ApiCtx<any, any, any>, next) {
    ctx.cache = false;
    ctx.request = ctx.req({
      url: `/workdata`,
    });
    yield next();
    const { data, ok } = yield ctx.json;
    if (!ok) {
      yield spawn(
        appNotify.run,
        appNotify({
          type: "error",
          message:
            "Erori la încărcarea datelor. Reincarcati pagina sau Login din nou.",
          duration: 7000,
        })
      );
      return;
    }
    const formatted = compose(
      reduce((acc: Record<string, IDBWorkdata>, item: IDBWorkdata) => {
        acc[item.WDI] = item;
        return acc;
      }, {})
    )(data);
    yield* put(workDataRepo.actions.set(formatted));
  }
);

export const saveRecord = api.post<Partial<IListRecord>>(
  "/workdata/wdi",
  { saga: leading },
  function* saveRecord(ctx: ApiCtx<any, any, any>, next) {
    const { WDI } = ctx.payload;
    const initialData = yield* select(workDataByIdSelector, WDI) || {};
    ctx.request = ctx.req({
      url: `/workdata/wdi`,
      body: JSON.stringify({ ...initialData, ...ctx.payload }),
    });
    yield next();
    const { data, ok } = yield ctx.json;
    if (!ok) {
      yield spawn(
        appNotify.run,
        appNotify({
          type: "error",
          message:
            "Erori la încărcarea datelor. Reincarcati pagina sau Login din nou.",
          duration: 7000,
        })
      );
      return;
    }
    const [{ OUT_WDI }] = data;
    yield* put(
      workDataRepo.actions.patch({
        [OUT_WDI]: { ...initialData, ...ctx.payload },
      })
    );
  }
);

export const deleteRecord = api.delete<{ WDI: string }>(
  "/workdata/wdi",
  { saga: leading },
  function* deleteRecord(ctx: ApiCtx<any, any, any>, next) {
    const { WDI } = ctx.payload;
    ctx.request = ctx.req({
      url: `/workdata/wdi`,
      method: "DELETE",
      body: JSON.stringify(ctx.payload),
    });
    yield next();
    const { data, ok } = yield ctx.json;
    if (!ok) {
      yield spawn(
        appNotify.run,
        appNotify({
          type: "error",
          message:
            "Erori la încărcarea datelor. Reincarcati pagina sau Login din nou.",
          duration: 7000,
        })
      );
      return;
    }
    const [{ OUT_WDI }] = data;
    yield* put(workDataRepo.actions.remove([OUT_WDI]));
  }
);

export const saveModel = thunks.create<IListState>(
  "saveModel",
  function* (ctx, next) {
    yield* put(workDataLayoutRepo.actions.set(ctx.payload));
    yield next();
  }
);
/* -------------------------------------------------------------------------- */
export const selectWorkData = createSelector(
  (state: RootState) => state[REPO_NAME],
  (data) => Object.values(data)
);
const listSelectors = workDataRepo.getSelectors(
  (state: RootState) => state[REPO_NAME]
);
export const workdDataSelector = (s: RootState) => listSelectors.selectTable(s);
export const workDataByIdSelector = (state: RootState, id: string) =>
  listSelectors.selectById(state, { id: id });
//"layoutID":0

const layoutSelectors = (state: RootState) => state[`${REPO_NAME}-layout`];

// export const selectListReportLoaded = createSelector(
export const workDataLayoutIDSelector = createSelector(
  layoutSelectors,
  (state) => {
    return state.layoutID;
  }
);

export const workDataLayoutStateSelector = createSelector(
  layoutSelectors,
  (state) => {
    return state;
  }
);

export const slectListReportLoadedID = createSelector(
  layoutSelectors,
  (state) => {
    return 0;
  }
);

/* -------------------------------------------------------------------------- */
/*        procedures causing some unwanted side-effects/renders in grid       */
/* -------------------------------------------------------------------------- */

const _bad_loadModel = api.get(
  "/workdata/model",
  { saga: leading },
  function* loadModel(ctx: ApiCtx<any, any, any>, next) {
    ctx.request = ctx.req({
      url: `/workdata/model`,
    });
    yield next();
    //: { data: IDBListLayout[]; ok: boolean }
    const { data, ok } = yield ctx.json;
    if (!ok) {
      console.log(
        "Erori la încărcarea datelor. Reincarcati pagina sau Login din nou."
      );
      return;
    }

    for (const item of data as IDBListLayout[]) {
      if (item.LIST_ORIGIN === appConstants.CONST_LSTORIGIN_WORKDATA) {
        const { B_GRIDSTATE } = item;
        const gridState = JSON.parse(B_GRIDSTATE);
        const layout = {
          listOrigin: item.LIST_ORIGIN,
          layoutID: item.LAYOUT_ID as TLayoutId,
          state: gridState,
        } as IListState;
        // as it is the only layout for now
        yield* put(workDataLayoutRepo.actions.set(layout));
      }
      // else put other layouts here ...
    }
  }
);

const _bad_saveModel = api.post<IListState>(
  "/workdata/model",
  { saga: leading },
  function* saveModel(ctx: ApiCtx<any, any, any>, next) {
    const gridStateString = JSON.stringify(ctx.payload);
    ctx.request = ctx.req({
      url: `/workdata/model`,
      method: "POST",
      body: JSON.stringify({
        LIST_ORIGIN: ctx.payload.listOrigin,
        LAYOUT_NAME: "default",
        LAYOUT_ID: ctx.payload.layoutID,
        B_GRIDSTATE: gridStateString,
      }),
    });
    yield next();
    const { data, ok } = yield ctx.json;
    if (!ok) {
      yield* spawn(
        appNotify.run,
        appNotify({
          type: "error",
          message: "Erori la salvarea modelului",
          duration: 7000,
        })
      );
      return;
    }
    // const [{ LYID }] = data;// (!)
    yield* put(workDataLayoutRepo.actions.set(ctx.payload));
    console.log("ctx.payload", ctx.payload);
    yield* spawn(
      appNotify.run,
      appNotify({
        type: "success",
        message: "Modelul a fost salvat",
        duration: 3000,
        position: "bottom",
      })
    );
  }
);
