import { Remarkable } from "remarkable";
import { isNull } from "lodash/fp";
import { CONST_RED_ALERT } from "@app/state/constants";
import { getI18n } from "react-i18next";
// import stripHtml from "string-strip-html";
//plugins that transforms markdown narative from db to draftjs object:
const MentionRegexp = /^@\[([^\]]*)\]\s*\(([^)]+)\)/;
const WTypeRegexp = /^#\[([^\]]*)\]\s*\(([^)]+)\)/;

const xd = new Remarkable();
xd.inline.ruler.enable(["ins", "mark"]);

/* -------------------------------------------------------------------------- */
const isRedAlert = (fieldValue: any) => {
  if (!fieldValue) {
    return false;
  }
  return fieldValue.split("?")?.[0] === CONST_RED_ALERT;
};

const displayAlert = (fieldValue: any) => {
  if (!fieldValue) {
    return "";
  }
  // OR GET THE TEMPLATE CURRENCY
  return getI18n().t(fieldValue.split("?")?.[1], {
    currency: fieldValue.split("?")?.[2] || "",
    lng: (fieldValue.split("?")?.[3] || "EN").toLowerCase(),
  });
};
/* -------------------------------------------------------------------------- */
/*                         MARJKDOWN TO DRAFT PLUGINS                         */
/* -------------------------------------------------------------------------- */

//PARSER - COMMON
export function mentionWrapper(remarkable: any) {
  remarkable.inline.ruler.push(
    "mention",
    function mention(state: any, silent: any) {
      // it is surely not our rule, so we could stop early
      if (!state.src || isNull(state.pos)) {
        return false;
      }

      if (state.src[state.pos] !== "@") {
        return false;
      }
      const match = MentionRegexp.exec(state.src.slice(state.pos));
      if (!match) {
        return false;
      }
      // in silent mode it shouldn't output any tokens or modify pending
      if (!silent) {
        state.push({
          type: "mention_open",
          name: match[1],
          id: match[2],
          level: state.level,
        });
        state.push({
          type: "text",
          content: match[1],
          level: state.level + 1,
        });
        state.push({
          type: "mention_close",
          level: state.level,
        });
      }
      // every rule should set state.pos to a position after token"s contents
      state.pos += match[0].length;
      return true;
    }
  );
}

export function footerWrapper(remarkable: any) {
  remarkable.inline.ruler.push(
    "mention",
    function mention(state: any, silent: any) {
      // console.log('state, silent', state, silent);
      // it is surely not our rule, so we could stop early
      if (!state.src || isNull(state.pos)) {
        return false;
      }

      if (state.src[state.pos] !== "@") {
        return false;
      }
      const match = MentionRegexp.exec(state.src.slice(state.pos));
      if (!match) {
        return false;
      }
      // in silent mode it shouldn't output any tokens or modify pending
      if (!silent) {
        state.push({
          type: "mention_open",
          name: match[1],
          id: match[2],
          level: state.level,
        });
        state.push({
          type: "text",
          parent: match[2],
          content: match[1],
          level: state.level + 1,
          flag: "mention_content",
        });
        state.push({
          type: "mention_close",
          level: state.level,
        });
      }
      // every rule should set state.pos to a position after token"s contents
      state.pos += match[0].length;
      return true;
    }
  );
}

export function footerPlainWrapper(remarkable: any) {
  remarkable.inline.ruler.push(
    "mention",
    function mention(state: any, silent: any) {
      // it is surely not our rule, so we could stop early
      if (!state.src || isNull(state.pos)) {
        return false;
      }

      if (state.src[state.pos] !== "@") {
        return false;
      }
      const match = MentionRegexp.exec(state.src.slice(state.pos));
      if (!match) {
        return false;
      }
      // in silent mode it shouldn't output any tokens or modify pending
      if (!silent) {
        state.push({
          type: "mention_open_plain",
          name: match[1],
          id: match[2],
          level: state.level,
        });
        state.push({
          type: "text",
          parent: match[2],
          content: match[1],
          level: state.level + 1,
        });
        state.push({
          type: "mention_close_plain",
          level: state.level,
        });
      }
      // every rule should set state.pos to a position after token"s contents
      state.pos += match[0].length;
      return true;
    }
  );
}

export function footerPdfWrapper(remarkable: any, options: any) {
  remarkable.inline.ruler.push(
    "mention",
    function mention(state: any, silent: any) {
      // it is surely not our rule, so we could stop early
      if (!state.src || isNull(state.pos)) {
        return false;
      }

      if (state.src[state.pos] !== "@") {
        return false;
      }
      const match = MentionRegexp.exec(state.src.slice(state.pos));
      if (!match) {
        return false;
      }
      // in silent mode it shouldn't output any tokens or modify pending
      if (!silent) {
        state.push({
          type: "mention_open_plain",
          name: match[1],
          id: match[2],
          isRedAlert: isRedAlert(options[match[2]]),
          level: state.level,
        });
        state.push({
          type: "text",
          parent: match[2],
          content: isRedAlert(options[match[2]])
            ? displayAlert(options[match[2]])?.trim()
            : options[match[2]]?.trim(),
          old_content: match[1],
          isRedAlert: isRedAlert(options[match[2]]),
          level: state.level + 1,
        });
        state.push({
          type: "mention_close_plain",
          level: state.level,
        });
      }
      // every rule should set state.pos to a position after token"s contents
      state.pos += match[0].length;
      return true;
    }
  );
}

//PARSER - COMMON
export function wTypeWrapper(remarkable: any) {
  remarkable.inline.ruler.push(
    "#mention",
    function mention_wty(state: any, silent: any) {
      // it is surely not our rule, so we could stop early
      if (!state.src || isNull(state.pos)) {
        return false;
      }

      if (state.src[state.pos] !== "#") {
        return false;
      }
      const match = WTypeRegexp.exec(state.src.slice(state.pos));
      if (!match) {
        return false;
      }

      //  console.log( stripHtml(xd.render(match[1]),{onlyStripTags:['p']}) )

      // in silent mode it shouldn't output any tokens or modify pending
      if (!silent) {
        state.push({
          type: "#mention_open",
          name: match[1],
          id: match[2],
          level: state.level,
        });

        state.push({
          type: "text",
          content: match[1],
          level: state.level + 1,
        });

        state.push({
          type: "#mention_close",
          level: state.level,
        });
      }

      // every rule should set state.pos to a position after token"s contents

      state.pos += match[0].length;

      return true;
    }
  );
}

// Renderer for draftjs
export const pluginOptions = {
  preserveNewlines: true,
  remarkablePlugins: [wTypeWrapper, mentionWrapper],
  remarkablePreset: "commonmark",
  remarkableOptions: {
    // breaks: true,
    // highlight: null,
    // html: true,
    // langPrefix: "language-",
    // linkTarget: "",
    // maxNesting: 20,
    // quotes: "“”‘’",
    typographer: true,
    // xhtmlOut: true,
    disable: {
      inline: ["text", "links", "htmltag", "autolink", "entity"],
    },

    enable: {
      inline: ["ins", "mark", "del"],
    },
  },
  blockStyles: {
    ins_open: "UNDERLINE",
    mark_open: "HIGHLIGHT",
    del_open: "STRIKETHROUGH",
  },
  blockTypes: {
    mention_open: function (item: any) {
      return {
        type: "unstyled",
        text: item.name,
      };
    },
    "#mention_open": function (item: any) {
      return {
        type: "unstyled",
        text: item.name,
      };
    },
  },
  //the entity map:
  blockEntities: {
    mention_open: function (item: any) {
      return {
        type: "mention",
        mutability: "IMMUTABLE",
        data: {
          mention: {
            id: item.id,
            name: item.name,
            ...item,
          },
        },
      };
    },
    "#mention_open": function (item: any) {
      return {
        type: "#mention",
        mutability: "IMMUTABLE",
        data: {
          mention: {
            id: item.id,
            name: item.name,
            ...item,
          },
        },
      };
    },
  },
};

/* -------------------------------------------------------------------------- */
/*                              MARKDOWN  TO HTML                             */
/* -------------------------------------------------------------------------- */

export function mention_hsh(md: any) {
  wTypeWrapper(md);
  md.renderer.rules["#mention_open"] = function (
    tokens: any,
    id: any,
    options: any,
    env: any
  ) {
    return `<span class="wk-hash" data-href=${tokens[0].id}>`;
  };
  md.renderer.rules["#mention_close"] = function (
    tokens: any,
    id: any,
    options: any,
    env: any
  ) {
    return "</span>";
  };
}

export function mention(md: any) {
  mentionWrapper(md);
  md.renderer.rules.mention_open = function (
    tokens: any,
    id: any,
    options: any,
    env: any
  ) {
    return `<span class="wk-at" data-href=${tokens[0].id}/>`;
  };
  md.renderer.rules.mention_close = function (
    tokens: any,
    id: any,
    options: any,
    env: any
  ) {
    return "</span>";
  };
}

export function mention_hsh__(md: any) {
  wTypeWrapper(md);
  md.renderer.rules["#mention_open"] = function (
    tokens: any,
    id: any,
    options: any,
    env: any
  ) {
    // let a =  xd.render(tokens[0].name);
    // console.log('a: ', a);
    return "<span>";
  };
  md.renderer.rules["#mention_close"] = function (
    tokens: any,
    id: any,
    options: any,
    env: any
  ) {
    return "</span>  ";
  };
}

/* -------------------------------------------------------------------------- */
/*                           INVOICE TEMPLATE STUFF                           */
/* -------------------------------------------------------------------------- */

export function invoiceFoot(md: any) {
  footerWrapper(md);
  md.renderer.rules.mention_open = function (
    tokens: any,
    id: any,
    options: any,
    env: any = {}
  ) {
    const alrt = isRedAlert(env?.[tokens?.[id]?.id]) || false;
    // data-href="${displayAlert(env[tokens[id].id])}" ---> will render as::after {content:attr(data-href);}
    return `<span class="span-with-data" style="${
      alrt ? "color:#ff0000" : "color:#333"
    };">`;
  };
  md.renderer.rules.mention_close = function () {
    return "</span>";
  };
  md.renderer.rules.text = function (
    tokens: any,
    id: any,
    options: any,
    env: any
  ) {
    if (tokens[id].level > 0) {
      return isRedAlert(env?.[tokens[id].parent])
        ? displayAlert(env?.[tokens[id].parent])
        : env[tokens[id].parent] || tokens[id].content;
    }
    return tokens[id].content;
  };
}

export function invoiceFootPlain(md: any) {
  footerPlainWrapper(md);
  // md.renderer.rules.strong_open
  md.renderer.rules.mention_open_plain = function (
    tokens: any,
    id: any,
    options: any,
    env = {} as any
  ) {
    const alrt = isRedAlert(env?.[tokens?.[id]?.id]) || false;
    return `<span class="span-with-data" style="${
      alrt ? "color:#ff0000" : "color:#333"
    };">`;
  };
  md.renderer.rules.mention_close_plain = function () {
    return "</span>";
  };
  md.renderer.rules.text = function (
    tokens: any,
    id: any,
    options: any,
    env: any
  ) {
    return env[tokens[id].parent] || tokens[id].content;
  };
}
