import { createAssign } from "robodux";
import {
  put,
  select,
  call,
  setLoaderError,
  setLoaderStart,
  setLoaderSuccess,
} from "saga-query";
import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import { compose, map, reduce, filter } from "lodash/fp";

import * as appConstants from "@app/state/constants";
import { thunks } from "../apis/api";
import {
  loadWorkData,
  workdDataSelector,
  workDataLayoutIDSelector,
  saveModel,
  workDataLayoutStateSelector,
  saveRecord,
  deleteRecord,
} from "./workdata";
import {
  selectOrgTypesKby,
  selectBranchesKby,
  selectSessionKeyBy,
  selectSessions,
  selectResources,
  // selectAnafNames,
} from "./structures";

import type { RootState } from "../rootState";
import type {
  IUIState,
  TUIPageName,
  IDBWorkdata,
  IListRecord,
  IDBSession,
  IListState,
} from "@app/types";

const REPO_NAME = "ui";

const init = {
  drawerOpen: false,
  currentPage: "nop",
  workSession: null,
} as IUIState;

export const uiRepo = createAssign<any>({
  name: REPO_NAME,
  initialState: init,
});

export const uiiApii = thunks.create<Partial<IUIState>>(
  "/uii",
  function* (ctx, next) {
    yield* put(setLoaderStart({ id: ctx.key }));
    const sui = yield* select((s) => s.ui);
    yield* put(uiRepo.actions.set({ ...sui, ...ctx.payload }));
    // initialize the combo with the last session //
    if (!sui.workSession) {
      const sessions = yield* select(selectSessions) as any;
      const maxSession = Object.keys(sessions)
        .map((key) => +key)
        .reduce((a, b) => Math.max(a, b), 0);
      yield* put(uiRepo.actions.set({ ...sui, workSession: maxSession }));
    }

    switch (ctx.payload?.currentPage) {
      case "workdata/any": {
        const db = yield* call(loadWorkData.run, loadWorkData()) as any;
        if (!db.json.ok) {
          yield* put(
            setLoaderError({ id: ctx.key, message: db.json.data.message })
          );
          return;
        }
        ctx.actions.push({ type: "batch=workdata" });
        break;
      }
      case "workdata/sq": {
        const db = yield* call(loadWorkData.run, loadWorkData()) as any;
        if (!db.json.ok) {
          yield* put(
            setLoaderError({ id: ctx.key, message: db.json.data.message })
          );
          return;
        }
        ctx.actions.push({ type: "batch=workdata" });
        break;
      }
      case "resources": {
        // show another page here -->
        ctx.actions.push({ type: "batch=resources" });
        break;
      }
      case "nop":
        ctx.actions.push({ type: "batch=nop" });
        break;
      default:
        break;
    }
    yield* put(
      setLoaderSuccess({
        id: ctx.key,
        meta: { something: "worked" },
      })
    );
    yield next();
  }
);

export const setWorkSession = thunks.create<{ workSession: string }>(
  "/setWorkSession",
  function* (ctx, next) {
    const sui = yield* select((s) => s.ui);
    yield* put(uiRepo.actions.set({ ...sui, ...ctx.payload }));
    yield next();
  }
);

export const currentListSaveModel = thunks.create<{
  listOrigin: string;
  gridState: IListState;
}>("currentListSaveModel", function* (ctx, next) {
  switch (ctx.payload.listOrigin) {
    case appConstants.CONST_LSTORIGIN_WORKDATA:
      {
        yield* call(saveModel.run, saveModel(ctx.payload.gridState));
      }
      break;
    case appConstants.CONST_LSTORIGIN_NOP:
      break;
    default:
      break;
  }
  yield next();
});

export const currentListSaveEdit = thunks.create<{
  listOrigin: string;
  dataset: Partial<IListRecord>;
}>("/currentListSaveEdit", function* (ctx, next) {
  switch (ctx.payload.listOrigin) {
    case appConstants.CONST_LSTORIGIN_WORKDATA:
      yield* call(saveRecord.run, saveRecord(ctx.payload.dataset));
      break;
    case appConstants.CONST_LSTORIGIN_NOP:
      break;
    default:
      break;
  }
  yield next();
});

export const currentListDelete = thunks.create<{
  listOrigin: string;
  WDI: string;
}>("currentListDelete", function* (ctx, next) {
  switch (ctx.payload.listOrigin) {
    case appConstants.CONST_LSTORIGIN_WORKDATA:
      yield* call(deleteRecord.run, deleteRecord({ WDI: ctx.payload.WDI }));
      break;
    case appConstants.CONST_LSTORIGIN_NOP:
      break;
    default:
      break;
  }
  yield next();
});

//selctors
function createParameterSelector(selector: (params: any) => any) {
  return (_: RootState, params: any) => selector(params);
}
const getListName = createParameterSelector((params) => params?.list || null);

const uiState = (state: any) => state.ui;
export const uiSelector = createSelector(uiState, (ui) => ui);
export const uiCurrentPageSelector = createSelector(
  uiState,
  (ui): TUIPageName => ui.currentPage
);

const getSagaCache = (state: RootState) => state?.["@@saga-query/data"] || {};

export const sagaQueryCache = createSelector(getSagaCache, (cache) => cache);
export const selectFullWorkData = createSelector(
  workdDataSelector,
  selectOrgTypesKby,
  selectBranchesKby,
  selectSessionKeyBy,
  selectResources,
  // selectAnafNames,
  uiState,
  (
    workdata,
    orgtypes,
    branches,
    sessions,
    resources,
    // anafnames,
    ui
  ): IListRecord[] => {
    const maxSession = Object.keys(sessions)
      .map((key) => +key)
      .reduce((a, b) => Math.max(a, b), 0);
    const result = compose(
      map((item: IDBWorkdata) => {
        return {
          ...item,
          ORGANIZATION_TYPE:
            orgtypes?.[`${branches[item?.BID]?.OTYID || -1}`]?.ORG_TYPE_NAME,
          BRANCH_NAME: branches[item?.BID]?.BRANCH_NAME,
          SESSION_NAME: sessions[item?.SID]?.SESSION_NAME,
          RESOURCE_NAME: resources[item?.RSID]?.FILE_NAME,
          //AD-HOC - Anaf Query - client request one by one //
          ANAF_QUERY: null,
          AQ_DENUMIRE: null,
          AQ_RECOM: null,
          AQ_ADRESA_FULL: null,
          AQ_TELEFON: null,
          AQ_LOCALITATE: null,
          AQ_JUDET: null,
          AQ_CAEN: null,
          AQ_LEVENSTEIN_SCORE: null,
        };
      }),
      // the last session //
      filter(
        (item: IDBWorkdata) => +item?.SID === (+ui?.workSession || maxSession)
      )
    )(workdata);
    return result as any;
  }
);

export const listSwitchSelector = createCachedSelector(
  getListName,
  selectFullWorkData,
  (listName, workdata) => {
    switch (listName) {
      case appConstants.CONST_LSTORIGIN_WORKDATA:
        return workdata;
      case appConstants.CONST_LSTORIGIN_NOP:
        return null;
      default:
        return null;
    }
  }
)((_, params) => {
  return params?.list || null;
});

export const currentWorkSessionSelector = createSelector(
  uiState,
  (ui): IDBSession => {
    return ui?.workSession || null;
  }
);

export const listLayoutIDSelector = createCachedSelector(
  getListName,
  workDataLayoutIDSelector,
  (listName, wdLayoutId) => {
    switch (listName) {
      case appConstants.CONST_LSTORIGIN_WORKDATA:
        return wdLayoutId;
      case appConstants.CONST_LSTORIGIN_NOP:
        return null;
      default:
        return null;
    }
  }
)((_, params) => {
  return params?.list || null;
});

export const selectListReportCurrentState = createCachedSelector(
  getListName,
  workDataLayoutStateSelector,
  (listName, wdLayout) => {
    switch (listName) {
      case appConstants.CONST_LSTORIGIN_WORKDATA:
        return wdLayout;
      case appConstants.CONST_LSTORIGIN_NOP:
        return null;
      default:
        return null;
    }
  }
)((_, params) => {
  return params?.list || null;
});
