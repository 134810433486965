import React, { useState, useEffect, useRef, Fragment } from "react";
import { useAppDispatch, useAppSelector } from "@app/state";
import { Tooltip } from "devextreme-react/tooltip";

import { useTranslation } from "react-i18next";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Item,
  LoadPanel,
  MasterDetail,
  Paging,
  Scrolling,
  Selection,
  Sorting,
  StateStoring,
  Toolbar,
} from "devextreme-react/data-grid";
import compose from "lodash/fp/compose";
import split from "lodash/fp/split";
import isNull from "lodash/fp/isNull";
import isUndefined from "lodash/fp/isUndefined";
import lowerCase from "lodash/fp/lowerCase";
import curry from "lodash/fp/curry";
import { uniq } from "lodash";
import { commonStyle } from "@app/style/commonForms";
import {
  setWorkSession,
  currentWorkSessionSelector,
  selectSessionsList,
  currentListSaveModel,
  appNotify,
} from "@app/state/units";

import { IDBSession, IListState } from "@app/types";
type TLayoutId = 0 | 1 | 2;

export const invisibleItemOpts = {
  location: "before",
  widget: "dxButton",
  options: {
    elementAttr: {
      id: "invisiblebutton",
    },
  },
};

export const layoutButtonOpts = ({
  layoutID,
  changeGridLayout,
}: {
  layoutID: TLayoutId;
  changeGridLayout: (e?: any) => void;
}) => {
  return {
    name: "layoutButton",
    location: "center",
    widget: "dxButton",
    options: {
      elementAttr: { id: "grdLayoutButton" },
      id: "grdLayoutButton",
      icon: layoutID === 1 ? "doc" : layoutID === 2 ? "fullscreen" : "file",
      hint: "Formatul vizualizarii: Portret | Vedere | Ecran intreg",
      onClick: changeGridLayout,
    },
  };
};

export const layoutButtonGroupOpts = ({
  layoutID,
  changeGridLayout,
}: {
  layoutID: TLayoutId;
  changeGridLayout: (e?: any) => void;
}) => {
  return {
    name: "layoutButton",
    location: "center",
    widget: "dxButtonGroup",
    options: {
      elementAttr: {
        id: "grdLayoutButton",
        class: "dx-button-group-layout",
      },
      items: [
        {
          icon: "file",
          tselector: 0,
          hint: "Formatul vizualizarii: Vedere",
        },
        {
          icon: "doc",
          tselector: 1,
          hint: "Formatul vizualizarii: Portret",
        },
        {
          icon: "fullscreen",
          tselector: 2,
          hint: "Formatul vizualizarii: Ecran intreg",
        },
      ],
      keyExpr: "tselector",
      stylingMode: "normal",
      selectedItemKeys: [layoutID], //  align font borders
      onItemClick: changeGridLayout,
    },
  };
};

export const sessionSelectOpts = () => {
  const dispatch = useAppDispatch();
  const sessionList = useAppSelector(selectSessionsList);
  const currentValue = useAppSelector(currentWorkSessionSelector);
  return {
    name: "sessionSelect",
    location: "center",
    widget: "dxSelectBox",
    options: {
      elementAttr: { id: "grdSessionSelect" },
      id: "grdSessionSelect",
      value: +currentValue,
      displayExpr: "SESSION_NAME",
      valueExpr: "SID",
      items: sessionList,
      onValueChanged: (e: any) => {
        dispatch(setWorkSession({ workSession: `${e.value}` }));
      },
      visible: sessionList.length > 1,
    },
  };
};

export const showDupsGroupOpts = ({
  ico,
  showDups,
}: {
  ico: "all" | "duplicates" | "unique" | undefined;
  showDups: (e?: any) => void;
}) => {
  return {
    name: "showDupsButton",
    location: "center",
    widget: "dxButtonGroup",
    options: {
      elementAttr: {
        class: "dx-button-group-fileterdups",
      },
      items: [
        {
          icon: "globe",
          tselector: "all",
          hint: "Toate inregistrarile",
        },
        {
          icon: "notequal",
          tselector: "unique",
          hint: "CAEN unic (in functie de numarul de salariati)",
        },
        {
          icon: "equal",
          tselector: "duplicates",
          hint: "CAEN multiplu (se fitreaza cele care apar de mai multe ori)",
        },
      ],
      keyExpr: "tselector",
      stylingMode: "normal",
      selectedItemKeys: [ico], //  align font borders
      onItemClick: showDups,
    },
  };
};

export const saveStateButtonOpts = ({
  listOrigin,
  anyListGridRef,
  resetToInitialState,
}: {
  listOrigin: string;
  anyListGridRef: React.MutableRefObject<DataGrid<any, any>> | undefined;
  resetToInitialState: (e?: any) => void;
}) => {
  const dispatch = useAppDispatch();

  const saveStatex = (e: any) => {
    if (!anyListGridRef) return;
    e.event.preventDefault();
    const crtState = anyListGridRef.current.instance.state();
    const crtState2: IListState = Object.assign(
      {},
      {
        ...crtState,
        layoutID:
          anyListGridRef.current?.props?.elementAttr?.["data-layoutID"] ?? 1,
      }
    );

    dispatch(
      currentListSaveModel({ listOrigin: listOrigin, gridState: crtState2 })
    );
    dispatch(
      appNotify({
        message: "Formatul a fost salvat pentru sesiunea curenta",
        type: "success",
        duration: 5000,
        position: "bottom",
      })
    );
    e.event.stopPropagation();
  };
  return {
    name: "saveStateButton",
    location: "center",
    widget: "dxButtonGroup",
    options: {
      elementAttr: { id: "grdSaveStateButton" },
      items: [
        {
          id: "grdRevertStateButton",
          icon: "revert",
          hint: "Revenire la FORMATUL implicit",
          onClick: resetToInitialState,
        },
        {
          id: "grdSaveStateButton",
          icon: "save",
          hint: "Salvarea FORMATULUI implicit, asa cum este acum afisat",
          onClick: saveStatex,
        },
      ],
    },
  };
};
export const initialSateButtonOpts = ({
  resetToInitialState,
}: {
  resetToInitialState: (e?: any) => void;
}) => {
  return {
    name: "initialStateButton",
    location: "center",
    widget: "dxButton",
    options: {
      elementAttr: { id: "grdInitialStateButton" },
      id: "grdInitialStateButton",
      icon: "revert",
      hint: "Revenire FORMATUL implicit",
      onClick: resetToInitialState,
    },
  };
};

export const exportToPdfOpts = ({
  exportGridToPdf,
}: {
  exportGridToPdf: (e?: any) => void;
}) => {
  return {
    name: "exportButton",
    location: "center",
    widget: "dxButton",
    options: {
      id: "exportButton",
      icon: "exportpdf",
      hint: "Export în PDF",
      onClick: exportGridToPdf,
    },
  };
};

export const columnChooserButtonOpts = ({
  customShowColumnChooser,
}: {
  customShowColumnChooser: (e?: any) => void;
}) => {
  return {
    name: "columnChooserButton",
    location: "center",
    widget: "dxButton",
    options: {
      hint: "Selecția coloanelor care vor fi afisate in tabel",
      onClick: customShowColumnChooser,
    },
  };
};

export const searchBoxOpts = ({
  tsSearchBoxRef,
  tsSrchBoxValue,
  tsSrchBoxValueChanged,
}: {
  tsSearchBoxRef: React.RefObject<any>;
  tsSrchBoxValue: string;
  tsSrchBoxValueChanged: (e?: any) => void;
}) => {
  const commonTextEditOptions = {
    ...commonStyle({ elementclass: "filledunderline" }),
  };
  return {
    location: "after",
    visible: true,
    widget: "dxTextBox",
    options: {
      ...commonTextEditOptions,
      inputAttr: {
        id: "searchbox-random-id-971",
        ref: tsSearchBoxRef,
      },
      stylingMode: "underlined",
      width: 250,
      placeholder: "Cautare in tabel",
      value: tsSrchBoxValue,
      onValueChanged: tsSrchBoxValueChanged,
      valueChangeEvent: "keyup",
      showClearButton: true,
    },
  };
};
// customGroupItemOpts('totalHours', 'ORELUCR')
export const customGroupItemOpts = (name: string, column: string) => ({
  name: name,
  summaryType: "custom",
  showInColumn: column,
  alignByColumn: column,
  displayFormat: "{0}",
  showInGroupFooter: false,
});
// customTotalItemOpts('totalHours', 'ORELUCR')
export const customTotalItemOpts = (name: string, column: string) => ({
  name: name,
  summaryType: "custom",
  showInColumn: column,
  displayFormat: "{0}",
});
// total currency split
export const groupCustomItem = (name: string) => ({
  name: name,
  column: name,
  summaryType: "custom",
  showInColumn: name,
  alignByColumn: true,
  displayFormat: "{0}",
});
// total currency split
export const groupCustomTotals = (name: string, label: string) => ({
  name: name,
  column: name,
  summaryType: "custom",
  showInColumn: name,
  alignByColumn: name,
  displayFormat: label ? `${label}:{0}` : "{0}",
  showInGroupFooter: false,
});
export const totalsCount = (name: string, label: string) => ({
  name: name,
  column: name,
  summaryType: "count",
  showInColumn: name,
  alignByColumn: name,
  displayFormat: label ? `${label}:{0}` : "{0}",
  showInGroupFooter: false,
});

export const totalsSum = (name: string, label: string) => ({
  name: name,
  column: name,
  summaryType: "sum",
  showInColumn: name,
  alignByColumn: name,
  displayFormat: label ? `${label}:{0}` : "{0}",
  showInGroupFooter: false,
});

export const groupSum = (name: string, label: string) =>
  ({
    name: name,
    column: name,
    summaryType: "sum",
    showInColumn: name,
    alignByColumn: name,
    displayFormat: label ? `${label}:{0}` : "{0}",
    showInGroupFooter: false,
  } as any);

const toMinutes = (x: string) =>
  compose((a) => parseInt(a?.[0]) * 60 + parseInt(a?.[1]), split(":"))(x);
//
const toHoursMinutes = (mins: number) => {
  const h = Math.floor(mins / 60);
  const m = mins % 60;
  const hh = h < 10 ? `0${h}` : h;
  const mm = m < 10 ? `0${m}` : m;
  return `${hh}:${mm}`;
};

export const calculateTotalHours = (options: any) => {
  if (options.name === "totalHours") {
    if (options.summaryProcess === "start") {
      options.totalValue = "0:00";
      options.totalMinutes = 0;
    } else if (options.summaryProcess === "calculate") {
      options.totalMinutes =
        options.totalMinutes + toMinutes(options.value.ORELUCR);
      options.totalValue = toHoursMinutes(options.totalMinutes);
    } else if (options.summaryProcess === "finalize") {
      const colNames = [];
      //
      for (let i = 0; i < options.component.columnCount(); i++) {
        if (options.component.columnOption(i, "groupIndex") > -1) {
          colNames.push(options.component.columnOption(i, "caption"));
        }
      }
      //
      options.totalValue =
        isNull(options.groupIndex) || isUndefined(options.groupIndex)
          ? options.totalValue
          : options.totalValue;
      // : `${lowerCase(colNames?.[options.groupIndex])}: ${options.totalValue}
      //   `;
    }
  }
};

export const calculateTotalValues = (options: any) => {
  // totals for groups for each currency
  if (
    [
      "VLAFCT_FTVA_TXT",
      "VALFCT_TVA_TXT",
      "VALFCT_CUTVA_TXT",
      "VALINC_TXT",
      "RESTINC_TXT",
      "VALFARATVACON_TXT",
      "VALTVACON_TXT",
      "VALFCON_TXT",
      "VALICON_TXT",
      "RESTICON_TXT",
      "VAL_TXT",
      "VAL_TVA_TXT",
      "TOTAL_TXT",
      "INCASAT_TXT",
      "REST_DE_INCASAT_TXT",
      "VAL_EUR_TXT",
      "VAL_TVA_EUR_TXT",
      "TOTAL_EUR_TXT",
      "INCASAT_EUR_TXT",
      "REST_DE_INCASAT_EUR_TXT",
    ].includes(options.name)
  ) {
    if (options.summaryProcess === "start") {
      options.totalValue = {
        CHF: 0,
        EUR: 0,
        GBP: 0,
        MDL: 0,
        RON: 0,
        USD: 0,
      };
    } else if (options.summaryProcess === "calculate") {
      try {
        const suma = (options.value || ("" as string)).split(" ");
        options.totalValue[suma[1]] =
          options.totalValue[suma[1]] + parseFloat(suma[0]);
      } catch (e) {
        console.log(e);
        console.log("options", options);
      }
    } else if (options.summaryProcess === "finalize") {
      const res = Object.entries(options.totalValue).reduce(
        (acc, [key, value]) => {
          return value
            ? `${acc === "0" ? "" : acc} ${(value as number).toFixed(
                2
              )} ${key} `
            : acc;
        },
        "0"
      );
      options.totalValue = `${res}`;
    }
  }
  //count distinct
  if (["CLIENT", "CTRDEN"].includes(options.name)) {
    if (options.summaryProcess === "start") {
      options.totalValue = [];
    } else if (options.summaryProcess === "calculate") {
      options.totalValue.push(options.value);
    } else if (options.summaryProcess === "finalize") {
      options.totalValue = uniq(options.totalValue).length;
    }
  }
};

export const calculateWithDistinct = (options: any) => {
  if (["CUI"].includes(options.name)) {
    if (options.summaryProcess === "start") {
      options.totalValue = [];
    } else if (options.summaryProcess === "calculate") {
      options.totalValue.push(options.value);
    } else if (options.summaryProcess === "finalize") {
      const all = options.totalValue.length;
      const uq = uniq(options.totalValue).length;
      options.totalValue = `unici:${uq} total: ${all}`;
    }
  }
};

export function LabelAnaf(data: any) {
  return (
    <React.Fragment>
      <div id="template-content">
        <i id="helpedInfo" className="dx-icon dx-icon-info"></i>
        <br />
        {data.text}
      </div>

      <Tooltip
        target="#helpedInfo"
        showEvent="mouseenter"
        hideEvent="mouseleave"
      >
        <div id="tooltip-content">{`
        Datele interogarii sunt disponibile temporar.
        Nu se vor salva ca atare in baza de date`}</div>
      </Tooltip>
    </React.Fragment>
  );
}
export const LabelLevenstein = (data: any) => {
  return (
    <React.Fragment>
      <div id="template-content">
        <i id="helpedInfo2" className="dx-icon dx-icon-info"></i>
        {"Scor potrivire"}
        <br />
        {/* {data.text} */}
      </div>

      <Tooltip
        target="#helpedInfo2"
        showEvent="mouseenter"
        hideEvent="mouseleave"
      >
        <div id="tooltip-content">{`Pentru potrivire tinem cont doar de CUI si denumirea societatii`}</div>
      </Tooltip>
    </React.Fragment>
  );
};
