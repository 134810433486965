import { createAssign } from "robodux";
import { AnyAction } from "redux";
import { put, spawn, call, delay } from "saga-query";
import { createSelector } from "reselect";
import { isNumber } from "lodash";
import { hideLoading } from "react-redux-loading-bar";

import { api } from "@app/state/apis/api";
import { runInitialLoad } from "./structures";
import { appNotify } from "./notify";

import type { ApiCtx } from "saga-query";
import type { RootState } from "../rootState";
import type { IAuth0User, IWhoAmI, IDBUser, IAppNotify } from "@app/types";
import { uiiApii } from "./ui";

const REPO_NAME = "login";
//Auth0User -> IDBUser -> IWhoAmI//
const noUser = {
  email: "",
  email_verified: false,
  name: "",
  nickname: "",
  picture: "",
  sub: "",
  updated_at: "",
  UID: null,
  RIGHTS: "",
  FIRST_NAME: "",
  LAST_NAME: "",
  DISPLAY_NAME: "",
  HOME_BRANCH: null,
  CONTACTS1: "",
  CONTACTS2: "",
  CONTACTS3: "",
  NOTES: "",
};
export const loginRepo = createAssign<IWhoAmI>({
  name: REPO_NAME,
  initialState: noUser,
  extraReducers: {
    login_test_action: (state: RootState, action: AnyAction) => {
      return { ...state, teststuff: action.payload };
    },
  },
});

export const setAuthResult = api.get<IAuth0User>(
  "/login/auth0",
  function* (ctx: ApiCtx<any, IAuth0User>, next) {
    // if auth0 returns false but the user is in then we leave the user in
    if (ctx.payload.email !== "") {
      loginRepo.actions.set(ctx.payload);
      localStorage.setItem("login_hint", ctx.payload.email);
    }
    ctx.request = ctx.req({
      url: `/login/auth0?${new URLSearchParams(ctx.payload)}`,
    });
    yield next();
    const { data } = yield ctx.json;
    const ldata = data?.[0] || {};
    const logindata = {
      UID: ldata.UID,
      RIGHTS: ldata.RIGHTS,
      FIRST_NAME: ldata.FIRST_NAME,
      LAST_NAME: ldata.LAST_NAME,
      DISPLAY_NAME: ldata.DISPLAY_NAME,
      HOME_BRANCH: ldata.HOME_BRANCH,
      CONTACTS1: ldata.CONTACTS1,
      CONTACTS2: ldata.CONTACTS2,
      CONTACTS3: ldata.CONTACTS3,
      NOTES: ldata.NOTES,
    };
    yield put(loginRepo.actions.set({ ...ctx.payload, ...logindata }));
    // start initial fetching  ....
    if (isNumber(ldata.UID) && ldata.UID > 0) {
      yield* call(runInitialLoad.run, runInitialLoad({ UID: ldata.UID }));
      yield* put(uiiApii({ currentPage: "workdata/any" }));
    } else {
      yield spawn(
        appNotify.run,
        appNotify({
          type: "error",
          message: "Utiliztorul nu are un identificator valid",
          duration: 5000,
        })
      );
    }
  }
);

export const logout = api.get(
  "/login/logout",
  function* (ctx: ApiCtx<any, unknown, any>, next): any {
    yield next();
    yield ctx.json;
    yield* put(loginRepo.actions.set(noUser));
    yield* put({ type: "logout/RESET_STORE" });
    yield* delay(700);
    yield* put(hideLoading());
  }
);
export const nop = api.get(
  "/nop",
  function* (ctx: ApiCtx<any, unknown, any>, next): any {
    yield next();
  }
);

const selectLogin = (state: RootState) => state[REPO_NAME];

export const isAuth0 = createSelector(
  selectLogin,
  (login) => login.email !== "" && login.email_verified
);

export const isAuth0AndAma = createSelector(selectLogin, (login) => {
  if (!login?.email) return false;
  return (
    (login.email || "") !== "" &&
    login.email_verified &&
    Number.isInteger(+login.UID) &&
    login.UID > 0
  );
});

export const selectors = {
  isAuth0,
  isAuth0AndAma,
};
export const actions = {
  setAuthResult,
  logout,
  nop,
};
