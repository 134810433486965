import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { applyPlugin } from "jspdf-autotable";
// import autoTable from 'jspdf-autotable';
import { Repeater } from "@repeaterjs/repeater";
import { timeout } from "@repeaterjs/timers";
import { run, withTimeout } from "effection";
import { confirm } from "devextreme/ui/dialog";
/* ----------------------------- the local state ---------------------------- */
import {
  CONST_LSTORIGIN_WORKDATA,
  CONST_LSTORIGIN_NOP,
  CONST_LISTORIGIN_DICTIONARY,
} from "@app/state/constants";
import * as local from "./pdf_state";
// sections, compontents, etc.
import { addTimeSheet0, addAnyGrid, addMasterDetailGrid } from "./section_grid";
import { addLogo } from "./section_logo";
import { titleSection, threeSections } from "./section_title";
import { drawALine } from "./section_draw";
import { addPageNo } from "./section_footer";

import "@app/style/Roboto-Regular-normal";
import "@app/style/Roboto-Bold-bold";
import "@app/style/Roboto-Italic-italic";
import "@app/style/Roboto-BoldItalic-bolditalic";
import { noop } from "lodash/fp";
import notify from "devextreme/ui/notify";

import type { Operation } from "effection";
import type { TPdfRootObject } from "@app/types";
import type { IPdfState } from "./pdf_state";
applyPlugin(jsPDF);
let lock = false;
/* -------------------------------------------------------------------------- 

  IMPORTANT: PRESERVE THE NAME OF PDF FILE CONSISTENT WITH THE VARIABLE IN DELPHI

  THE DELPHI HOOK WILL LAUNCH THE PREVIEWER DEPENDING ON THE FILENAME
  DELPHI: 
  procedure TfrmCentralQuick.Chromium1BeforeDownload... 
    ...
     if(suggestedName = 'TimeSheet.pdf') then
   begin...
    launch the pdf extension window

  -------------------------------------------------------------------------- */
const pdfState = local.pdfState;

const timesheetPdf = async (pdfRootObject: TPdfRootObject, refs: any) => {
  const r = new Repeater(async (push, stop) => {
    push("initializing");
    console.log("pdfRootObject", pdfRootObject);
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.setFont("Roboto-Regular", "normal");
    pdf.setFontSize(7);
    // init state - we define  pdfKey - as a session key to keep the state isolated.
    const pdfKey = pdfState.init({
      thisLanguage: pdfRootObject.thisLanguage,
      thisCurrency: pdfRootObject.thisCurrency,
      logo: {
        apiKey: "logo360x160", // e.g. the api request for the asset
        width: "360px",
        height: "160px",
      },
      orientation: "p",
    });
    await addLogo(pdf, pdfKey);
    await push("logo added");
    await threeSections(pdf, pdfKey, ["", "Fisa de presatii", ""]);
    await push("title added");
    await drawALine(pdf, pdfKey);
    await push("line added");
    await threeSections(pdf, pdfKey, [
      "",
      "",
      `emisa la data de ${local.roDate(new Date())}`,
    ]);
    await push("date added");
    await addTimeSheet0(pdf, pdfKey, refs.tsGridRef);
    await push("timesheet added");
    await addPageNo(pdf, pdfKey);
    await push("footer added");
    pdf.save(pdfRootObject.filename || "report.pdf");
    await push("pdf saved");
    pdfState.clear();
    await push("state cleared");
    lock = false;
    stop();
  });

  for await (const value of r) {
    noop(value);
  }
};

const masterDetailPdf = async (pdfRootObject: TPdfRootObject, refs: any) => {
  const {
    showReportHeader = false,
    filename,
    layoutParam = "p",
  } = pdfRootObject;
  const r = new Repeater(async (push, stop) => {
    push("0 - initializing");
    // console.log('pdfRootObject', pdfRootObject);
    const pdf = new jsPDF(layoutParam, "mm", "a4");
    pdf.setFont("Roboto-Regular", "normal");
    pdf.setFontSize(9);
    // init state - we define  pdfKey - as a session key to keep the state isolated.
    const pdfKey = pdfState.init({
      thisLanguage: pdfRootObject.thisLanguage,
      thisCurrency: pdfRootObject.thisCurrency,
      orientation: layoutParam,
      logo: {
        apiKey: "logo360x160", // e.g. the api request for the asset
        width: "360px",
        height: "160px",
      },
    });

    if (showReportHeader) {
      await addLogo(pdf, pdfKey);
      await push("logo added");
    }
    await threeSections(pdf, pdfKey, [
      "",
      `Lista de ${filename
        .replace(".pdf", "")
        .replace("_", " - ")
        .toLowerCase()}`,
      "",
    ]);
    await push("title added");
    await drawALine(pdf, pdfKey);
    await push("line added");
    await threeSections(pdf, pdfKey, [
      "",
      "",
      `emisa la data de ${local.roDate(new Date())}`,
    ]);
    await push("date added");
    await addMasterDetailGrid(pdf, pdfKey, refs.anyListGridRef, refs.detailRef);
    await push("timesheet added");
    await addPageNo(pdf, pdfKey);
    await push("footer added");
    await pdf.save(filename);
    await push("pdf saved");
    await pdfState.clear();
    await push("state cleared");
    lock = false;
    stop();
  });

  try {
    for await (const value of Repeater.race([r, timeout(10000)])) {
      noop(value);
    }
  } catch (e) {
    notify(
      `A aparut o eroare la generarea raportului (2). \n
      Se anuleaza generarea. \n
      Va rugam incercati din nou. \n
      Daca eroarea persista, contactati administratorul.`,
      "error",
      2000
    );
    console.log("#217-pdfEngine error", e);
    lock = false;
  }
};

function* ePlainlistPdf(
  pdfRootObject: TPdfRootObject,
  refs: any
): Operation<void> {
  try {
    const {
      showReportHeader = false,
      filename,
      layoutParam = "p",
    } = pdfRootObject;
    const pdf = new jsPDF(layoutParam, "mm", "a4");
    pdf.setFont("Roboto-Regular", "normal");
    pdf.setFontSize(9);

    const pdfKey = pdfState.init({
      thisLanguage: pdfRootObject.thisLanguage,
      thisCurrency: pdfRootObject.thisCurrency,
      orientation: layoutParam,
      logo: {
        apiKey: "logo360x160", // e.g. the api request for the asset
        width: "360px",
        height: "160px",
      },
    });

    if (showReportHeader) {
      yield withTimeout(300, addLogo(pdf, pdfKey));
    }

    yield withTimeout(
      100,
      titleSection(pdf, pdfKey, [
        `Lista de ${filename
          .replace(".pdf", "")
          .replace("_", " - ")
          .toLowerCase()}`,
      ])
    );

    drawALine(pdf, pdfKey);
    yield withTimeout(
      100,
      threeSections(pdf, pdfKey, [
        "",
        "",
        `emisa la data de ${local.roDate(new Date())}`,
      ])
    );

    yield withTimeout(700, addAnyGrid(pdf, pdfKey, refs.anyListGridRef));

    const msg = pdfState.getWarnSignal(pdfKey);
    if (msg) {
      const questionResult = yield confirm(`<p>${msg}<p>`, "Atentionare");
      if (!questionResult) {
        pdfState.clear();
        lock = false;
        return;
      }
    }
    yield withTimeout(100, addPageNo(pdf, pdfKey));
    pdf.save(filename);
    pdfState.clear();
    lock = false;
    return;
  } catch (e: any) {
    if (Object.hasOwn(e, "name") && e?.name === "TimeoutError") {
      notify(
        `A aparut o eroare la generarea raportului (1). \n
    Se anuleaza generarea. \n
    Va rugam incercati din nou. \n
    Daca eroarea persista, contactati administratorul.`,
        "error",
        5000
      );
      lock = false;
      return;
    } else {
      throw e; // rethrow //
    }
  }
}

export async function pdfEngine(pdfRootObject: TPdfRootObject, refs: any) {
  if (lock) {
    notify(
      "Un alt raport este in curs de generare. Va rugam asteptati pana la finalizarea acestuia.",
      "warning",
      2000
    );
    return;
  }
  lock = true;
  try {
    switch (pdfRootObject.filename) {
      case "TimeSheet.pdf": {
        return await timesheetPdf(pdfRootObject, refs);
      }

      case `${CONST_LISTORIGIN_DICTIONARY[CONST_LSTORIGIN_WORKDATA]}.pdf`: {
        return await run(ePlainlistPdf(pdfRootObject, refs));
      }
      default:
        timesheetPdf(pdfRootObject, refs);
        break;
    }
  } catch (e) {
    console.log("e - 1058", e);
    notify(
      `A aparut o eroare la generarea raportului (#3). Va rugam incercati din nou. 
      Daca eroarea persista, contactati administratorul.`,
      "error",
      5000
    );
    console.log("#218-pdfEngine error", e);
    lock = false;
  }
}

export async function getAsset(pdfKey: string, apiKey: string) {
  const asset = await pdfState.getAsset(pdfKey, apiKey);
  return asset;
}

export function destroySession() {
  pdfState.clear();
}
